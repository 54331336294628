.c-form-group {
    margin-bottom: 1.25rem;

    &__body {
        display: flex;
    }

    .form-lable {
        @include fs-16;
        color: $black-2c;
        margin-bottom: 0.75rem;
    }
    
    .form-control {
        background-color: $white;
        border: 1px solid $gray-cf;
        border-radius: 0.313rem;
        padding: 0.25rem 0.875rem;
        @include fs-14;
        color: $black-2c;
        height: 2.5rem;
    }

    select.form-control {
        background: url(../images/icons/arrow-bottom-pink.svg) no-repeat right 1rem center $white;
        padding-right: 2rem;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    textarea.form-control {
        height: 8.125rem;
        resize: none;
    }

    .we-170 {
        width: 10.625rem;
    }

    &__preview {
        background-color: $gray-f5;
        border-radius: 0.313rem;
        padding: 2.25rem;

        .c-icon--mobile {
            margin-left: 1.625rem;
        }
    }

}


// Checkbox slider
.c-switch {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: 1.25rem;
    margin-bottom: 0;

    input { 
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .c-switch__slider {
            background-color: $green;

            &:before {
                -webkit-transform: translateX(1.25rem);
                -ms-transform: translateX(1.25rem);
                transform: translateX(1.25rem);
            }
        }

        &:focus + .c-switch__slider {
            box-shadow: 0 0 1px #2196F3;
        }
    }

    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $gray-cf;
        -webkit-transition: .4s;
        transition: .4s;
        overflow: hidden;
    
        &:before {
            position: absolute;
            content: "";
            height: 1.125rem;
            width: 1.125rem;
            left: 1px;
            bottom: 1px;
            background-color: $white;
            -webkit-transition: .4s;
            transition: .4s;
            box-shadow: 0 0.25rem 0.25rem rgba($black-2c, 0.3);
        }

        /* Rounded sliders */
        &--round {
            border-radius: 0.625rem;
            
            &:before {
                border-radius: 50%;
            }
        }
    }
}

// Custom search
.c-search {
    display: flex;
    align-items: center;
    background-color: $white;
    border: 1px solid $gray-cc;
    border-radius: 1rem;
    padding: 0 0.75rem;

    .c-icon { 
        margin-right: 0.5rem; 
        cursor: pointer;
    }
    .search-control {
        @include fs-14;
        color: $black-2c;
        border: 0;
        width: 15.875rem;
        height: 1.875rem;
        
        &:focus {
            outline: 0;
        }
    }
}  

// Medium screen / tablet
@media screen and (max-width: 768px) {
    .c-form-group {
        &__preview {
            padding: 1rem 0.5rem;
            .c-icon--mobile { margin-left: 1rem; }
        }
    }
} 

