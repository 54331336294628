
$black: #000000;
$black-05: #050505;
$black-2c: #1C252C;
$black-22: #222222;
$black-33: #33414A;

$gray-ab: #ABB0B4;
$gray-ee: #EEEEEE;
$gray-f5: #F5F5F5;
$gray-dd: #DDDDDD;
$gray-cf: #CFD3D6;
$gray-cc: #CCCCCC;
$gray-70: #708390;

$white: #ffffff;

$pink: #CF0B48;

$green: #0BCF65;


