.container {
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 250px;
}
.container_editor {
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 250px;
}

.container_disable {
  border: 0px;
  min-height: auto;
}

.button {
  flex: 1;
  top:0;
  cursor: pointer;
}

.img {
  border-radius: 5px;
  padding: 3px;
  text-align: center;
  width: 25px;
  height: 25px;
  fill: #000;
  margin-top: 5px;
  background-color: transparent;
}

.img_active {
  fill: #fff;
  background-color: #000;
}

.toolbar {
  position: relative;
  margin: 0 -16px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
}
