@mixin fs-11 {
    font-size: 0.688rem;
}

@mixin fs-12 {
    font-size: 0.75rem;
}

@mixin fs-14 {
    font-size: 0.875rem;
}

@mixin fs-16 {
    font-size: 1rem;
}

@mixin fs-18 {
    font-size: 1.125rem;
}

@mixin fs-24 {
    font-size: 1.5rem;
}

@mixin fs-26 {
    font-size: 1.625rem;
}

@mixin fs-30 {
    font-size: 1.875rem;
}

@mixin fs-32 {
    font-size: 2rem;
}

@mixin fs-40 {
    font-size: 2.5rem;
}