
%i-bg {
    display: inline-flex;
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: auto;
}

// Width
.we-04 {
    width: 0.25rem;
}
.we-10 {
    width: 0.625rem;
}
.we-12 {
    width: 0.75rem;
}
.we-14 {
    width: 0.875rem;
}
.we-16 {
    width: 1rem;
}
.we-18 {
    width: 1.125rem;
}
.we-20 {
    width: 1.25rem;
}
.we-24 {
    width: 1.5rem;
}


// Height 
.hi-10 {
    height: 0.625rem;
}
.hi-12 {
    height: 0.75rem;
}
.hi-14 {
    height: 0.875rem;
}
.hi-16 {
    height: 1rem;
}
.hi-18 {
    height: 1.125rem;
}
.hi-20 {
    height: 1.25rem;
}
.hi-24 {
    height: 1.5rem;
}
.hi-36 {
    height: 2.25rem;
}

.c-icon {
    @extend %i-bg;

    &--search {
        background-image: url(../images/icons/search.svg);
        @extend .we-12;
        @extend .hi-12;
    }

    &--play {
        background-image: url(../images/icons/play-1.svg);
        @extend .we-16;
        @extend .hi-16;
    }

    &--image {
        background-image: url(../images/icons/image-1.svg);
        @extend .we-16;
        @extend .hi-14;
    }

    &--close {
        background-image: url(../images/icons/close.svg);
        @extend .we-12;
        @extend .hi-12;
    }

    &--clock {
        background-image: url(../images/icons/clock.svg);
        @extend .we-14;
        @extend .hi-14;
    }

    &--gray-round {
        background-image: url(../images/icons/gray-round.svg);
        @extend .we-14;
        @extend .hi-14;
    }

    &--success {
        background-image: url(../images/icons/success.svg);
        @extend .we-14;
        @extend .hi-14;
    }

    &--close-red {
        background-image: url(../images/icons/close-red.svg);
        @extend .we-14;
        @extend .hi-14;
    }

    &--quation {
        background-image: url(../images/icons/quation.svg);
        @extend .we-14;
        @extend .hi-14;
    }

    &--mail {
        background-image: url(../images/icons/notification.svg);
        @extend .we-20;
        @extend .hi-20;
    }

    &--more {
        background-image: url(../images/icons/more.svg);
        @extend .we-04;
        @extend .hi-16;
    }

    &--notification {
        background-image: url(../images/icons/notification.svg);
        @extend .we-20;
        @extend .hi-20;
    }

    &--prompt {
        background-image: url(../images/icons/notification.svg);
        @extend .we-18;
        @extend .hi-14;
    }

    &--home {
        background-image: url(../images/icons/home.svg);
        @extend .we-14;
        @extend .hi-14;
    }

    &--cap {
        background-image: url(../images/icons/cap.svg);
        @extend .we-14;
        @extend .hi-14; 
    }

    &--controls {
        background-image: url(../images/icons/controls.svg);
        @extend .we-14;
        @extend .hi-14; 
    }

    &--users {
        background-image: url(../images/icons/users.svg);
        @extend .we-14;
        @extend .hi-14; 
    }

    &--users-group {
        background-image: url(../images/icons/user-grop.svg);
        @extend .we-16;
        @extend .hi-16; 
    }

    &--plus {
        background-image: url(../images/icons/plus.svg);
        @extend .we-18;
        @extend .hi-18; 
    }

    &--plus-pink {
        background-image: url(../images/icons/plus-pink.svg);
        @extend .we-18;
        @extend .hi-18; 
    }

    &--content {
        background-image: url(../images/icons/content.svg);
        @extend .we-14;
        @extend .hi-14; 
    }
    &--team-managment {
        background-image: url(../images/icons/team-mangment.svg);
        @extend .we-14;
        @extend .hi-14; 
    }
    &--content-academy {
        background-image: url(../images/icons/caps.svg);
        @extend .we-14;
        @extend .hi-14; 
    }
    &--website-managment {
        background-image: url(../images/icons/settings.svg);
        @extend .we-14;
        @extend .hi-14; 
    }
    &--user-managment {
        background-image: url(../images/icons/user-magment.svg);
        @extend .we-14;
        @extend .hi-14; 
    }
    &--club-setting {
        background-image: url(../images/icons/controls.svg);
        @extend .we-14;
        @extend .hi-14; 
    }
    &--team-setting {
        background-image: url(../images/icons/team-setting.svg);
        @extend .we-14;
        @extend .hi-14; 
    }
    &--account-setting {
        background-image: url(../images/icons/users.svg);
        @extend .we-14;
        @extend .hi-14; 
    }
    

    &--facebook {
        background-image: url(../images/icons/facebook.svg);
        @extend .we-20;
        @extend .hi-20; 
    }

    &--facebook-color {
        background-image: url(../images/icons/facebook-color.svg);
        @extend .we-20;
        @extend .hi-20; 
    }

    &--instagram {
        background-image: url(../images/icons/instagram.svg);
        @extend .we-20;
        @extend .hi-20; 
    }

    &--instagram-color {
        background-image: url(../images/icons/instagram-color.svg);
        @extend .we-20;
        @extend .hi-20; 
    }

    &--twitter {
        background-image: url(../images/icons/twitter.svg);
        @extend .we-20;
        @extend .hi-20; 
    }
    
    &--youtube {
        background-image: url(../images/icons/youtube.svg);
        @extend .we-20;
        @extend .hi-20; 
    }


    &--briefing {
        background-image: url(../images/icons/whistle.svg);
        @extend .we-16;
        @extend .hi-16; 
    }
    &--newspaper {
        background-image: url(../images/icons/folded-newspaper.svg);
        @extend .we-16;
        @extend .hi-16; 
    }
    &--whistle {
        background-image: url(../images/icons/whistle.svg);
        @extend .we-16;
        @extend .hi-16; 
    }
    &--calendar {
        background-image: url(../images/icons/small-calendar.svg);
        @extend .we-16;
        @extend .hi-16; 
    }
    &--image-white {
        background-image: url(../images/icons/image.svg);
        @extend .we-16;
        @extend .hi-16; 
    }
    &--document {
        background-image: url(../images/icons/copy-document.svg);
        @extend .we-16;
        @extend .hi-16; 
    }


    &--comments {
        background-image: url(../images/icons/comments.svg);
        @extend .we-24;
        @extend .hi-24;
    }
    &--exchange {
        background-image: url(../images/icons/exchange.svg);
        @extend .we-24;
        @extend .hi-24;
    }
    &--whistle-big {
        background-image: url(../images/icons/whistle-1.svg);
        @extend .we-24;
        @extend .hi-24;
    }
    &--shape {
        background-image: url(../images/icons/shape.svg);
        @extend .we-24;
        @extend .hi-24;
    }

    &--close-1 {
        background-image: url(../images/icons/close-thik.svg);
        @extend .we-12;
        @extend .hi-12;
    }
    &--close-white {
        background-image: url(../images/icons/close-thik-white.svg);
        @extend .we-12;
        @extend .hi-12;
    }

    &--hamburger {
        background-image: url(../images/icons/hamburger.svg);
        @extend .we-24;
        @extend .hi-24;
    }

    &--mobile {
        background-image: url(../images/icons/mobile.svg);
        @extend .we-20;
        @extend .hi-36;
    }

    &--delete {
        background-image: url(../images/icons/delete.svg);
        @extend .we-16;
        @extend .hi-18;
    }

    &--edit-gray {
        background-image: url(../images/icons/edit-gray.svg);
        @extend .we-16;
        @extend .hi-16;
    }

    &--edit {
        background-image: url(../images/icons/edit.svg);
        @extend .we-16;
        @extend .hi-16;
    }

    &--menu {
        background-image: url(../images/icons/menu.svg);
        @extend .we-16;
        @extend .hi-16;
    }
    
    &--facebook-gray {
        background-image: url(../images/icons/facebook-gray.svg);
        @extend .we-16;
        @extend .hi-16;
    }

    &--twitter-gray {
        background-image: url(../images/icons/twitter-gray.svg);
        @extend .we-16;
        @extend .hi-16;
    }

    &--mail-gray {
        background-image: url(../images/icons/mail-gray.svg);
        @extend .we-16;
        @extend .hi-16;
    }

    &--arrow-left {
        background-image: url(../images/icons/arrow-left.svg);
        @extend .we-16;
        @extend .hi-16;
    }
}