.c-popup {
    .modal-header {
        border: 0;
        padding: 0 0 1.25rem;

        .close {
            padding: 0;
            margin: 0;
            opacity: 1;
        }
    }
    .modal-dialog { 
        max-width: 36.25rem; 

        &--wd-810 {
            max-width: 50.625rem;
        }

        &--wd-75p {
            max-width: 75%;
        }
    }
    .modal-content {
        border: 0;
        padding: 1.875rem;
    }
    .modal-title {
        @include fs-24;
        color: $black-2c;
        font-weight: 600;
    }
    .modal-body {
        padding: 0;
    }

    .p-0 { padding: 0; }
    .b-radius-0 { border-radius: 0; }
}

.modal-backdrop {
    background-color: $black-2c;
    
    &.show { opacity: 0.8; }
}

// Media upload
.c-upload-media {
    background-color: $gray-ee;
    border: 1px dashed #4E5D67;
    border-radius: 0.625rem;
    height: 24.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;

    &--hi-300 {
        height: 18.75rem;
    }
    
    &--hi-auto {
        height: auto;
    }

    &--ptb-22 {
        padding: 1.375rem 0;
    }

    &__drag-image {
        @include fs-24;
        color: rgba($black-22, 0.5);
        margin-bottom: 2.625rem;

        &--mb-18 {
            margin-bottom: 1.125rem;
        }
    }
    &__text {
        @include fs-14;
        color: rgba($black-22, 0.5);
        margin-bottom: 0.5rem;
    }
    &__body {
        &--row {
            display: flex;

            .c-upload-media__text {
                margin: 0 0.5rem 0 0;
                display: flex;
                align-items: center;
            }
        }
    }

    .c-button {
        padding-top: 0.313rem;
        padding-bottom: 0.313rem;
    }
}


// Add event 
.c-add-event {
    .mb-20 {
        margin-bottom: 1.25rem;
    }

    .c-button-group {
        display: flex;

        .c-button + .c-button {
            margin-left: 0.75rem;
        }
    }
}


// Image details
.c-image-details {
    
    &__image {}
    &__body {
        padding: 1.875rem;
    }

    .c-button-group {
        display: flex;
        justify-content: space-between;
        padding-top: 1.25rem;

        .c-button + .c-button {
            margin-left: 0.75rem;
        }
    }
}


// Add a player
.c-add-player {
    .c-button-group {
        display: flex;
        .c-button + .c-button { margin-left: 0.625rem; } 
    }
}



// Extra large screen / wide desktop
@media screen and (max-width: 1320px) {
    // Image details
    .c-image-details {   
        &__image { max-width: 37.5rem; }
    }    
}


// Medium screen / tablet
@media screen and (max-width: 1200px) {
    // Media upload
    .c-upload-media {
        &__drag-image {
            @include fs-18;
        }
        &__button {
            .c-button + .c-button { margin-top: 0.5rem; }
        }
    }

    // Image details
    .c-image-details {   
        &__image { max-width: 26.25rem; }
    }
}

// Medium screen / tablet
@media screen and (max-width: 768px) {
    .c-popup .modal-dialog--wd-810 {
        max-width: 36.25rem;
    }

    // Image details
    .c-image-details {   
        &__image { max-width: 100%; }
    }
}


// Small screen / phone
@media screen and (max-width: 576px) {
    .c-popup {
        .modal-content {
            padding: 1rem;
        }
        .modal-dialog {
            &--wd-75p,
            &--wd-810 { max-width: inherit; }
        }
    }

    // Image details
    .c-image-details {
        &__body {
            padding: 1rem;
        }
    }
}
