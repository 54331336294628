.c-pagination {
    display: flex;
    justify-content: flex-end;
    padding: 1.25rem;

    .pagination {
        margin: 0;
        padding: 0;

        .page-item {
            padding: 0 0.313rem;

            &.active {
                .page-link {
                    border-color: $black;
                    background-color: $black;
                    color: $white;
                }    
            }
            .page-link {
                background-color: $white;
                border-radius: 0.313rem;
                border: solid 1px $gray-cf;
                padding: 0;
                width: 2.25rem;
                height: 2.25rem;
                @include fs-14;
                color: $black-05;
                font-weight: 700;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .c-button {
        height: 2.25rem;
        line-height: normal;
        padding: 0.5rem 1.25rem;
    }
}

// Small screen / phone
@media screen and (max-width: 576px) {
    .c-pagination {
        padding: 0.625rem;
    }
}