.c-left-nav {
    background: $black-33;
    box-shadow: 0 0.125rem 0.25rem $gray-dd;
    border-radius: 0.313rem;

    .c-accordion {
        border-radius: 0 0 0.313rem 0.313rem;
        background-color: $black-2c;
    }
}

.c-nav-logo {
    text-align: center;
    padding-bottom: 1.5rem;

    &__edit {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: -3.5rem;
        border-radius: 50%;
        background-color: $black-33;
        width: 1.625rem;
        height: 1.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__image {
        position: relative;
        width: 6.25rem;
        margin: 0 auto 0.875rem;

        img { margin-top: -3.5rem; }
    }
    &__title {
        color: $white;
        font-weight: 700;
    }
}

.c-nav-card {
    border-top: solid 1px $black-33;

    &__button {
        color: $white;
        text-transform: capitalize;
        border: 0;
        border-radius: 0;
        background: transparent;
        width: 100%;
        padding: 1.375rem;
        text-align: left;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:focus {
            outline: 0;
        }
        &:hover {
            color: $white;
            text-decoration: none;
        }

        .c-icon {
            margin-right: 0.875rem;
        }
    }

    &__subnav {
        background-color: $black-33;
        
        &__list {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                padding: 0.5rem 0;

                a {
                    @include fs-14;
                    color: $white;
                    display: flex;
                    align-items: center;
                    padding: 0.25rem 1.375rem;
                    position: relative;

                    .c-icon {
                        margin-right: 0.875rem;
                    }

                    &:hover {
                        text-decoration: none;
                        background-color: rgba($white, 0.1);

                        &::before {
                            content: '';
                            background: transparent linear-gradient(180deg, #CF0B48 0%, #4333D9 100%) 0% 0%;
                            width: 0.313rem;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                        }
                    }
                }

                &.active {
                    a {
                        background-color: rgba($white, 0.1);

                        &::before {
                            content: '';
                            background: transparent linear-gradient(180deg, #CF0B48 0%, #4333D9 100%) 0% 0%;
                            width: 0.313rem;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                        }
                    }
                }
            }
        }
        
    }
}