body {
    font-family: 'Exo', sans-serif;
}


// Background color class
.c-bg-gray-ee {
    background-color: $gray-ee;
}

.c-wrapper {
    padding: 1.875rem 0;
    min-height: calc(100vh - 3.75rem);
}


// Layout class
.c-first-column {
    width: 15rem;
    padding-top: 3.188rem;
}


// Margin class

.mb-30 {
    margin-bottom: 1.875rem;
}

.mr-60 {
    margin-right: 3.75rem;
  }
  
// Large screen / desktop
@media screen and (max-width: 992px) {
    .c-main-nav {
        position: fixed;
        right: -100%;
        top: 0;
        z-index: 5;
        height: 100vh;
        padding-top: 8.85rem;
        width: 100%;
        background-color: rgba($white, 0.75);
        /*transition: right 0.5s;*/

        .c-left-nav {
            width: 15rem;
            margin-left: auto;
            margin-right: 1.875rem;
        }

        .c-accordion {
            max-height: calc(100vh - 16.5rem);
            overflow-y: auto;
        }

        &.--show {
            right: 0;
        }
    }
}

// Small screen / phone
@media screen and (max-width: 576px) {
    .c-last-column,
    .c-main-nav {
        &--mpt-40 {
            padding-top: 2.5rem;
        }
    }
}



// Small screen / phone
@media screen and (min-width: 576px) {
    
}

// Medium screen / tablet
@media screen and (min-width: 768px) {
    .c-wrapper {
        padding: 1.875rem 0.938rem;
    }
}

// Large screen / desktop
@media screen and (min-width: 992px) {
    .c-first-column {
        display: block;
    }
    .c-last-column {
        width: 21.875rem;
        padding-top: 3.188rem;
    }
}

// Extra large screen / wide desktop
@media screen and (min-width: 1200px) {
    
}