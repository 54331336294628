@font-face {
  font-family: Exo-Black;
  src: url(../font/Exo-Black.ttf);
}

@font-face {
  font-family: Exo-Bold;
  src: url(../font//Exo-Bold.ttf);
}

@font-face {
  font-family: Exo-ExtraBold;
  src: url(../font/Exo-ExtraBold.ttf);
}

@font-face {
  font-family: Exo-Medium;
  src: url(../font/Exo-Medium.ttf);
}

@font-face {
  font-family: Exo-Regular;
  src: url(../font/Exo-Regular.ttf);
}

@font-face {
  font-family: Exo-SemiBold;
  src: url(../font/Exo-SemiBold.ttf);
}

.f-Exo-Bold {
  font-family: Exo-Bold;
}

.f-Exo-Medium {
  font-family: Exo-Medium;
}

.f-Exo-SemiBold {
  font-family: Exo-SemiBold;
}

body {
  font-family: Exo-Regular;
}

.c-bg-gray-ee {
  background-color: #eeeeee;
}

.c-wrapper {
  padding: 1.875rem 0;
  min-height: calc(100vh - 3.75rem);
}

.c-first-column {
  width: 15rem;
  padding-top: 3.188rem;
}

.mb-30 {
  margin-bottom: 1.875rem;
}

.mr-60 {
  margin-right: 3.75rem;
}

@media screen and (max-width: 992px) {
  .c-main-nav {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 5;
    height: 100vh;
    padding-top: 8.85rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    /*transition: right 0.5s;*/
  }
  .c-main-nav .c-left-nav {
    width: 15rem;
    margin-left: auto;
    margin-right: 1.875rem;
  }
  .c-main-nav .c-accordion {
    max-height: calc(100vh - 16.5rem);
    overflow-y: auto;
  }
  .c-main-nav.--show {
    right: 0;
  }
}

@media screen and (max-width: 576px) {
  .c-last-column--mpt-40,
  .c-main-nav--mpt-40 {
    padding-top: 2.5rem;
  }
}

@media screen and (min-width: 768px) {
  .c-wrapper {
    padding: 1.875rem 0.938rem;
  }
}

@media screen and (min-width: 992px) {
  .c-first-column {
    display: block;
  }
  .c-last-column {
    width: 21.875rem;
    padding-top: 3.188rem;
  }
}

.c-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-indent: -9999px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: auto;
}

.we-04,
.c-icon--more {
  width: 0.25rem;
}

.we-10 {
  width: 0.625rem;
}

.we-12,
.c-icon--search,
.c-icon--users,
.c-icon--close-1,
.c-icon--close-white {
  width: 0.75rem;
}

.we-14,
.c-icon--clock,
.c-icon--gray-round,
.c-icon--success,
.c-icon--close-red,
.c-icon--quation,
.c-icon--home,
.c-icon--home-black,
.c-icon--cap,
.c-icon--controls,
.c-icon--content-management,
.c-icon--content,
.c-icon--team-managment,
.c-icon--content-academy,
.c-icon--website-managment,
.c-icon--user-managment,
.c-icon--share,
.c-icon--match-fixture,
.c-icon--club-setting,
.c-icon--moderation,
.c-icon--team-setting,
.c-icon--account-setting {
  width: 0.875rem;
}

.we-16,
.c-icon--play,
.c-icon--image,
.c-icon--photo,
.c-icon--users-group,
.c-icon--club-management,
.c-icon--league-management,
.c-icon--master-dashboard,
.c-icon--briefing,
.c-icon--newspaper,
.c-icon--whistle,
.c-icon--calendar,
.c-icon--image-white,
.c-icon--document,
.c-icon--delete,
.c-icon--edit-red,
.c-icon--edit-gray,
.c-icon--edit,
.c-icon--menu,
.c-icon--facebook-gray,
.c-icon--twitter-gray,
.c-icon--mail-gray,
.c-icon--instagram-gray,
.c-icon--close,
.c-icon--arrow-left {
  width: 1rem;
}

.c-icon--arrow-down,
.c-icon--arrow-up {
  width: 0.7rem;
}

.we-18,
.c-icon--prompt,
.c-icon--plus,
.c-icon--club-admin-request,
.c-icon--loader,
.c-icon--plus-pink {
  width: 1.125rem;
}

.c-icon--loader {
  width: 1rem;
}

.we-20,
.c-icon--mail,
.c-icon--notification,
.c-icon--facebook,
.c-icon--facebook-color,
.c-icon--instagram,
.c-icon--instagram-color,
.c-icon--twitter,
.c-icon--twitter-color,
.c-icon--youtube,
.c-icon--mobile {
  width: 1.25rem;
}

.we-24,
.c-icon--comments,
.c-icon--exchange,
.c-icon--whistle-big,
.c-icon--shape,
.c-icon--hamburger {
  width: 1.5rem;
}

.hi-10 {
  height: 0.625rem;
}

.hi-12,
.c-icon--search,
.c-icon--users,
.c-icon--close-1,
.c-icon--close-white {
  height: 0.75rem;
}

.hi-14,
.c-icon--image,
.c-icon--photo,
.c-icon--clock,
.c-icon--gray-round,
.c-icon--success,
.c-icon--close-red,
.c-icon--quation,
.c-icon--prompt,
.c-icon--home,
.c-icon--home-black,
.c-icon--cap,
.c-icon--controls,
.c-icon--content-management,
.c-icon--content,
.c-icon--team-managment,
.c-icon--content-academy,
.c-icon--website-managment,
.c-icon--user-managment,
.c-icon--share,
.c-icon--match-fixture,
.c-icon--club-setting,
.c-icon--moderation,
.c-icon--team-setting,
.c-icon--account-setting {
  height: 0.875rem;
}

.hi-16,
.c-icon--play,
.c-icon--more,
.c-icon--users-group,
.c-icon--club-management,
.c-icon--league-management,
.c-icon--master-dashboard,
.c-icon--briefing,
.c-icon--newspaper,
.c-icon--whistle,
.c-icon--calendar,
.c-icon--image-white,
.c-icon--document,
.c-icon--edit-red,
.c-icon--edit-gray,
.c-icon--edit,
.c-icon--menu,
.c-icon--facebook-gray,
.c-icon--twitter-gray,
.c-icon--mail-gray,
.c-icon--instagram-gray,
.c-icon--close,
.c-icon--arrow-left {
  height: 1rem;
}

.c-icon--arrow-down,
.c-icon--arrow-up {
  height: 0.7rem;
}

.hi-18,
.c-icon--plus,
.c-icon--club-admin-request,
.c-icon--plus-pink,
.c-icon--delete {
  height: 1.125rem;
}

.hi-20,
.c-icon--mail,
.c-icon--notification,
.c-icon--facebook,
.c-icon--facebook-color,
.c-icon--instagram,
.c-icon--instagram-color,
.c-icon--twitter,
.c-icon--twitter-color,
.c-icon--youtube {
  height: 1.25rem;
}

.hi-24,
.c-icon--comments,
.c-icon--exchange,
.c-icon--whistle-big,
.c-icon--shape,
.c-icon--hamburger {
  height: 1.5rem;
}

.hi-36,
.c-icon--mobile {
  height: 2.25rem;
}

.c-icon--search {
  background-image: url(../images/icons/search.svg);
}

.c-icon--play {
  background-image: url(../images/icons/play-1.svg);
}

.c-icon--image {
  background-image: url(../images/icons/image-1.svg);
}
.c-icon--photo {
  background-image: url(../images/icons/photo.svg);
}

.c-icon--close {
  background-image: url(../images/icons/close.svg);
}

.c-icon--clock {
  background-image: url(../images/icons/clock.svg);
}

.c-icon--gray-round {
  background-image: url(../images/icons/gray-round.svg);
}

.c-icon--success {
  background-image: url(../images/icons/success.svg);
}

.c-icon--close-red {
  background-image: url(../images/icons/close-red.svg);
}

.c-icon--quation {
  background-image: url(../images/icons/quation.svg);
}

.c-icon--mail {
  background-image: url(../images/icons/notification.svg);
}

.c-icon--more {
  background-image: url(../images/icons/more.svg);
}

.c-icon--notification {
  background-image: url(../images/icons/notification.svg);
}

.c-icon--prompt {
  background-image: url(../images/icons/notification.svg);
}

.c-icon--home {
  background-image: url(../images/icons/home.svg);
}

.c-icon--home-black {
  background-image: url(../images/icons/home-black.svg);
}

.c-icon--cap {
  background-image: url(../images/icons/cap.svg);
}

.c-icon--controls {
  background-image: url(../images/icons/controls.svg);
}

.c-icon--users {
  background-image: url(../images/icons/users.svg);
}

.c-icon--club-management {
  background-image: url(../images/icons/ic_club_management.svg);
}

.c-icon--league-management {
  background-image: url(../images/icons/ic_league_management.svg);
}

.c-icon--master-dashboard {
  background-image: url(../images/icons/master-dashboard.svg);
}

.c-icon--club-admin-request {
  background-image: url(../images/icons/ic_club_admin_request.svg);
}

.c-icon--content-management {
  background-image: url(../images/icons/content-management.svg);
}

.c-icon--users-group {
  background-image: url(../images/icons/user-grop.svg);
}

.c-icon--plus {
  background-image: url(../images/icons/plus.svg);
}

.c-icon--loader {
  background-image: url(../images/loader.svg);
}

.c-icon--plus-pink {
  background-image: url(../images/icons/plus-pink.svg);
}

.c-icon--content {
  background-image: url(../images/icons/content.svg);
}

.c-icon--team-managment {
  background-image: url(../images/icons/team-mangment.svg);
}

.c-icon--content-academy {
  background-image: url(../images/icons/cap.svg);
}

.c-icon--website-managment {
  background-image: url(../images/icons/settings.svg);
}

.c-icon--user-managment {
  background-image: url(../images/icons/user-magment.svg);
}

.c-icon--match-fixture {
  background-image: url(../images/icons/match-fixture.svg);
}

.c-icon--club-setting {
  background-image: url(../images/icons/controls.svg);
}

.c-icon--moderation {
  background-image: url(../images/icons/ic_moderation.svg);
}

.c-icon--team-setting {
  background-image: url(../images/icons/team-setting.svg);
}

.c-icon--account-setting {
  background-image: url(../images/icons/users.svg);
}

.c-icon--facebook {
  background-image: url(../images/icons/facebook.svg);
}

.c-icon--facebook-color {
  background-image: url(../images/icons/facebook-color.svg);
}

.c-icon--instagram {
  background-image: url(../images/icons/instagram.svg);
}

.c-icon--instagram-color {
  background-image: url(../images/icons/instagram-color.svg);
}

.c-icon--twitter {
  background-image: url(../images/icons/twitter.svg);
}

.c-icon--twitter-color {
  background-image: url(../images/icons/twitter-color.svg);
}

.c-icon--youtube {
  background-image: url(../images/icons/youtube.svg);
}

.c-icon--briefing {
  background-image: url(../images/icons/whistle.svg);
}

.c-icon--newspaper {
  background-image: url(../images/icons/folded-newspaper.svg);
}

.c-icon--whistle {
  background-image: url(../images/icons/whistle.svg);
}

.c-icon--calendar {
  background-image: url(../images/icons/small-calendar.svg);
}

.c-icon--image-white {
  background-image: url(../images/icons/image.svg);
}

.c-icon--document {
  background-image: url(../images/icons/copy-document.svg);
}

.c-icon--comments {
  background-image: url(../images/icons/comments.svg);
}

.c-icon--exchange {
  background-image: url(../images/icons/exchange.svg);
}

.c-icon--whistle-big {
  background-image: url(../images/icons/whistle-1.svg);
}

.c-icon--shape {
  background-image: url(../images/icons/shape.svg);
}

.c-icon--close-1 {
  background-image: url(../images/icons/close-thik.svg);
}

.c-icon--close-white {
  background-image: url(../images/icons/close-thik-white.svg);
}

.c-icon--hamburger {
  background-image: url(../images/icons/hamburger.svg);
}

.c-icon--mobile {
  background-image: url(../images/icons/mobile.svg);
}

.c-icon--delete {
  background-image: url(../images/icons/delete.svg);
}

.c-icon--edit-red {
  background-image: url(../images/icons/edit-red.svg);
}

.c-icon--edit-gray {
  background-image: url(../images/icons/edit-gray.svg);
}

.c-icon--edit {
  background-image: url(../images/icons/edit.svg);
}

.c-icon--menu {
  background-image: url(../images/icons/menu.svg);
}

.c-icon--facebook-gray {
  background-image: url(../images/icons/facebook-gray.svg);
}

.c-icon--twitter-gray {
  background-image: url(../images/icons/twitter-gray.svg);
}

.c-icon--instagram-gray{
  background-image: url(../images/icons/instagram-gray.svg);
}

.c-icon--mail-gray {
  background-image: url(../images/icons/mail-gray.svg);
}

.c-icon--arrow-left {
  background-image: url(../images/icons/arrow-left.svg);
}
.c-icon--arrow-up {
  background-image: url(../images/icons/arrow-up.svg);
}
.c-icon--arrow-down {
  background-image: url(../images/icons/arrow-bottom-pink.svg);
}

.c-icon--share {
  background-image: url(../images/icons/share.svg);
}

.c-button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0.313rem;
  padding: 0.525rem 1.25rem;
  cursor: pointer;
  font-size: 0.875rem;
}

.c-button--fs-16 {
  font-size: 1rem;
}

.c-button--small {
  padding: 0.313rem 0.75rem;
}

.c-button--bold {
  font-weight: 700;
}

.c-button--icons {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-button--icons .c-icon {
  margin-right: 0.5rem;
}

.c-button:hover {
  text-decoration: none;
}

.c-button--we-160 {
  width: 10rem;
}

.c-button--we-176 {
  width: 11rem;
}

.c-button--we-80 {
  width: 5rem;
}

.c-button--we-120 {
  width: 7.5rem;
}

.c-button--bg {
  border: 0;
}

.c-button--bg--white {
  color: #1c252c !important;
  background-color: #ffffff !important;
  border: solid 1px #cfd3d6 !important;
}

.c-button--bg--white:hover {
  color: #1c252c;
}

.c-button--bg--pink {
  color: #ffffff;
  background-color: #cf0b48;
}

.c-button--bg--pink:hover {
  color: #ffffff;
}

.c-button--bg--green {
  color: #ffffff;
  background-color: #0bcf65;
}

.c-button--bg--green:hover {
  color: #ffffff;
}

.c-button--bg--black-33 {
  color: #ffffff;
  background-color: #33414a;
}

.c-button--bg--black-33:hover {
  color: #ffffff;
}

.c-button--bg--pink-gradiant {
  color: #ffffff;
  background: transparent -webkit-gradient(linear, right top, left top, from(#cf0b48), to(#4333d9)) 0%
    0% no-repeat padding-box;
  background: transparent linear-gradient(270deg, #cf0b48 0%, #4333d9 100%) 0% 0% no-repeat
    padding-box;
}

.c-button--bg--pink-gradiant:hover {
  color: #ffffff;
}

.c-button--br {
  border-width: 1px;
  border-style: solid;
}

.c-button--br--gray-ab {
  color: #1c252c;
  border-color: #abb0b4;
}

.c-button--br--gray-ab:hover {
  color: #1c252c;
}

.c-button--br--gray-70 {
  color: #ffffff;
  border-color: #708390;
}

.c-button--br--gray-70:hover {
  color: #ffffff;
}

.c-button-links {
  font-size: 0.875rem;
  color: #cf0b48;
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-button-links .c-icon {
  margin-right: 0.25rem;
}

.c-delete {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.875rem;
  color: #1c252c;
  background-color: transparent;
  border: 0;
}

.c-delete .c-icon {
  margin-right: 0.5rem;
}

.c-user-dropdown__button {
  border: 0;
  background: transparent;
  padding: 0;
}

.c-user-dropdown__button::after {
  display: none;
}

.c-user-dropdown__button:focus {
  outline: 0;
}

.c-more-dropdown__button {
  cursor: pointer;
}

.c-more-dropdown__button::after {
  display: none;
}

.c-more-dropdown__menu {
  width: 14.625rem;
  padding: 0;
  margin: 0.5rem 0 0 0;
  border: 0;
  -webkit-box-shadow: 0 0 1.875rem rgba(34, 34, 34, 0.34);
  box-shadow: 0 0 1.875rem rgba(34, 34, 34, 0.34);
}

.c-more-dropdown .dropdown-item {
  font-size: 0.875rem;
  color: #1c252c;
  font-weight: 500;
  padding: 0.75rem 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-menu .dropdown-item:active {
  background-color: #cfd3d6;
}

.c-more-dropdown .dropdown-item .c-icon {
  margin-right: 0.625rem;
}

.c-more-dropdown .dropdown-item + .dropdown-item {
  border-top: solid 1px #cfd3d6;
}

.c-breadcrumb {
  padding-bottom: 1rem;
  font-size: 0.75rem;
}

.c-breadcrumb__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-breadcrumb__item {
  color: #1c252c;
  padding-right: 1rem;
  margin-right: 0.625rem;
  background: url(../images/icons/arrow-right.svg) no-repeat right top 0.125rem;
}

.c-breadcrumb__item_first {
  color: #1c252c;
  padding-right: 0.75rem;
  margin-right: 0.625rem;
  background: url(../images/icons/arrow-right.svg) no-repeat right top;
}

.c-breadcrumb__item:last-child {
  background: transparent;
  margin: 0;
  padding: 0;
}

.c-breadcrumb__item .c-icon {
  margin-right: 0.625rem;
}

.c-breadcrumb__item a {
  color: rgba(28, 37, 44, 0.5);
}

.c-breadcrumb__item a:hover {
  color: #1c252c;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .c-breadcrumb {
    padding-bottom: 2rem;
  }
}

.c-left-nav {
  background: #33414a;
  -webkit-box-shadow: 0 0.125rem 0.25rem #dddddd;
  box-shadow: 0 0.125rem 0.25rem #dddddd;
  border-radius: 0.313rem;
}

.c-left-nav .c-accordion {
  border-radius: 0 0 0.313rem 0.313rem;
  background-color: #1c252c;
}

.c-nav-logo {
  text-align: center;
  padding-bottom: 1.5rem;
}

.c-nav-logo__edit {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -3.5rem;
  border-radius: 50%;
  background-color: #33414a;
  width: 1.625rem;
  height: 1.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-nav-logo__image {
  position: relative;
  margin: 0 auto 0.875rem;
}

.c-nav-logo__image img {
  margin-top: -3.5rem;
}

.c-nav-logo__title {
  color: #ffffff;
}

.c-nav-card {
  border-top: solid 1px #33414a;
}

.c-nav-card__button {
  color: #ffffff;
  text-transform: capitalize;
  border: 0;
  border-radius: 0;
  background: transparent;
  width: 100%;
  padding: 1.375rem;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.c-nav-card__button:focus {
  outline: 0;
}

.c-nav-card__button:hover {
  color: #ffffff;
  text-decoration: none;
}

.c-nav-card__button .c-icon {
  margin-right: 0.875rem;
}

.c-nav-card__subnav {
  background-color: #33414a;
}

.c-nav-card__subnav__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.c-nav-card__subnav__list li {
  padding: 0.5rem 0;
}

.c-nav-card__subnav__list li a {
  font-size: 0.875rem;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 1.375rem;
  position: relative;
}

.c-nav-card__subnav__list li a .c-icon {
  margin-right: 0.875rem;
}

.c-nav-card__subnav__list li a:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.c-nav-card__subnav__list li a:hover::before {
  content: '';
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#cf0b48),
      to(#4333d9)
    ) 0% 0%;
  background: transparent linear-gradient(180deg, #cf0b48 0%, #4333d9 100%) 0% 0%;
  width: 0.313rem;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.c-nav-card__subnav__list li.active a {
  background-color: rgba(255, 255, 255, 0.1);
}

.c-nav-card__subnav__list li.active a::before {
  content: '';
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#cf0b48),
      to(#4333d9)
    ) 0% 0%;
  background: transparent linear-gradient(180deg, #cf0b48 0%, #4333d9 100%) 0% 0%;
  width: 0.313rem;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.c-accordion button.active {
  background-color: #cf0b48 !important;
}

.c-accordion button.active-bottom::after {
  content: '';
  background: url(../images/icons/arrow-bottom.svg) no-repeat;
  background-size: 12px 12px;
  position: absolute;
  width: 12px;
  height: 12px;
  left: 215px;
}
.c-accordion button.active-drop {
  background-color: #cf0b48 !important;
}

.c-accordion button.active-drop-up::after {
  content: '';
  background: url(../images/icons/arrow-up.svg) no-repeat;
  background-size: 12px 12px;
  position: absolute;
  width: 12px;
  height: 12px;
  left: 215px;
}

.sidebar-last-item {
  border-radius: 0px 0px 5px 5px !important;
}

.c-card {
  -webkit-box-shadow: 0 0.125rem 0.25rem #dddddd;
  box-shadow: 0 0.125rem 0.25rem #dddddd;
  border-radius: 0.313rem;
  margin-bottom: 1.25rem;
}

.c-card--dark .c-card__header {
  background-color: #33414a;
  padding: 0.625rem 1.25rem;
}

.c-card--dark .c-card__header__right .c-button + .c-button {
  margin-left: 0.625rem;
}

.c-card--dark .c-card__header__title-1 {
  color: #ffffff;
  font-size: 1.125rem;
  font-style: normal;
}

.c-card--dark .c-card__body {
  background-color: #1c252c;
}

.c-card__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.313rem 0.313rem 0 0;
}

.c-card__header--align-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.c-card__header.--ptb-10 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.c-card__header .--min-h50 {
  min-height: 3.125rem;
}

.c-card__header__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-card__header__title__icons {
  width: 1.875rem;
  height: 1.875rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 0.625rem;
  border-radius: 50%;
}

.c-card__header__title .--bold {
  font-weight: 700;
  margin: 0 0.25rem;
}

.c-card__header__title-1 {
  font-size: 1rem;
  font-style: italic;
}

.c-card__header__left {
  font-size: 0.875rem;
  color: #1c252c;
}

.c-card__header__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-card__header__right .c-button + .c-button {
  margin-left: 0.625rem;
}

.c-card__header__right .c-icon {
  cursor: pointer;
}

.c-card__header__right .c-icon + .c-icon {
  margin-left: 2rem;
}

.c-card__body,
.c-card__footer {
  background-color: #f5f5f5;
  border-radius: 0.313rem;
}

.c-card__body--mptb-10,
.c-card__footer--mptb-10 {
  padding: 0.625rem;
}

.c-card__body .c-button-group,
.c-card__footer .c-button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-card__body .c-button-group .c-button + .c-button,
.c-card__footer .c-button-group .c-button + .c-button {
  margin-left: 0.625rem;
}

.c-card__footer {
  background-color: #ffffff;
}

.c-card-news {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-card-news .mt-auto {
  margin-top: auto;
}

.c-card-news__image {
  border-radius: 0.313rem 0 0 0.313rem;
}

.c-card-news__body {
  width: 100%;
  padding: 1rem 1.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.c-card-news__title {
  font-size: 1rem;
  color: #1c252c;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.c-card-news__text {
  font-size: 0.75rem;
  color: #33414a;
  margin-bottom: 0;
}

.c-card-news__text-muted {
  font-size: 0.75rem;
  color: rgba(51, 65, 74, 0.5);
}

.c-card-training {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-card-training__body {
  width: 100%;
  padding: 1rem;
}

.c-card-training__title {
  font-size: 1rem;
  color: #1c252c;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.c-card-training__text {
  font-size: 0.75rem;
  color: #33414a;
  margin-bottom: 0.5rem;
}

.c-card-training__time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.75rem;
  color: #33414a;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.c-card-training__time .c-icon {
  margin-right: 0.5rem;
}

.c-card-training__others {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
}

.c-card-training__progress {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.875rem;
  color: #1c252c;
  margin-right: 20px;
}

.c-card-training__progress .c-icon {
  margin-right: 0.25rem;
}

.c-tasks {
  padding: 0.875rem 0.625rem;
  font-size: 0.875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #ffffff;
}

.c-tasks:nth-child(odd) {
  background-color: rgba(238, 238, 238, 0.5);
}

.c-tasks__title {
  font-weight: 700;
  color: #cf0b48;
}

.c-tasks__text {
  color: #33414a;
}

.c-tasks .c-button {
  padding: 0.25rem 0.75rem;
}

.c-tasks__left {
  line-height: normal;
}

.c-traffic-footer > div.row > div + div {
  border-left: solid 1px #cfd3d6;
}

.c-traffic-data {
  padding: 0.625rem 1.25rem;
  color: #1c252c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.c-traffic-data__text {
  font-size: 0.875rem;
  max-width: 3.4rem;
}

.c-traffic-data__number {
  font-size: 1.875rem;
}

.c-revenue-content {
  padding: 1.25rem 0.625rem 0.625rem;
}

.c-revenue-content__title {
  font-size: 1rem;
  color: #1c252c;
  font-style: italic;
  margin-bottom: 0.625rem;
  margin-left: 12px;
}

.c-revenue-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0.375rem 0.625rem;
}

.c-revenue-item:nth-child(odd) {
  background-color: rgba(238, 238, 238, 0.5);
}

.c-revenue-item__text,
.c-revenue-item__price {
  font-size: 0.875rem;
}

.c-revenue-item__text {
  color: #cf0b48;
}

.c-revenue-item__price {
  text-align: right;
  font-weight: 700;
  color: #1c252c;
}

.c-club-revenue {
  padding: 1.5rem;
}

.c-club-revenue .row > div + div .c-club-revenue-card {
  padding-top: 1rem;
}

.c-club-revenue-card {
  text-align: center;
  color: #1c252c;
  line-height: normal;
}

.c-club-revenue-card .c-icon--triangle--up {
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid #0bcf65;
}

.c-club-revenue-card .c-icon--triangle--down {
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.5rem solid #cf0b48;
}

.c-club-revenue-card__price {
  font-size: 2.5rem;
  font-weight: 700;
}

.c-club-revenue-card__text {
  font-size: 0.875rem;
}

.c-sponsored__header__body {
  line-height: normal;
}

.c-sponsored__header__title {
  font-size: 1rem;
  font-weight: 700;
  color: #1c252c;
}

.c-sponsored__header__text {
  font-size: 0.75rem;
  color: rgba(28, 37, 44, 0.5);
}

.c-sponsored__image {
  padding: 0 0.25rem 0.25rem;
}

.c-match {
  background-color: #33414a;
  padding: 1.25rem;
}

.c-match-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-match-card--right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.c-match-card--right .c-match-card__image {
  margin-left: 2.5rem;
}

.c-match-card--left .c-match-card__image {
  margin-right: 2.5rem;
}

.c-match-card__text {
  font-size: 0.75rem;
  color: #ffffff;
}

.c-match-card__image {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.c-match-versus {
  text-align: center;
  color: #ffffff;
}

.c-match-versus__date {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.c-match-versus__time {
  font-size: 0.75rem;
}

.c-news-list .c-news-card:nth-child(odd) {
  background-color: rgba(238, 238, 238, 0.5);
}

.c-news-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  background-color: #ffffff;
}

.c-news-card__title {
  font-size: 1rem;
  color: #cf0b48;
  font-weight: 700;
  margin-bottom: 0;
}

.c-news-card__title a {
  color: #cf0b48;
  text-decoration: underline;
}

.c-news-card__text {
  font-size: 0.85rem;
  color: #33414a;
  margin-bottom: 0;
}

.c-news-card__date {
  font-size: 0.75rem;
  color: rgba(51, 65, 74, 0.5);
}

.c-news-card__image {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 0.313rem;
  overflow: hidden;
  margin-right: 1rem;
}

.c-news-card__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 1rem;
  width: 100%;
}

.c-news-card__body__left-part {
  padding-right: 1rem;
}

.c-news-card__body__right-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.c-news-card__body__right-part .c-icon--more {
  margin-top: 1.375rem;
}

.c-news-switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem 0 0;
}

.c-news-switch__label {
  font-size: 0.75rem;
  color: #33414a;
  margin-bottom: 0.25rem;
}

.c-news-switch--hr {
  padding-right: 0;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 1.25rem;
}

.c-news-switch--hr .c-switch {
  margin-right: 0.5rem;
}

.c-media-library {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0.125rem 0.25rem #dddddd;
  box-shadow: 0 0.125rem 0.25rem #dddddd;
  border-radius: 0.313rem;
}

.c-media-library__filters {
  border-bottom: solid 1px rgba(207, 211, 214, 0.5);
  padding: 0.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-media-library__filters__lpart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-media-library__filters__rpart {
  margin-left: auto;
}

.c-media-library__filters .c-form-group {
  margin-left: 0.625rem;
}

.c-media-library__filters .c-form-group select.form-control {
  width: 11.25rem;
  height: 1.875rem;
}

.c-media-library__body {
  padding: 0.8rem;
}

.c-media-library .c-tabs-button--add-news .nav-item .nav-link {
  height: 2.5rem;
}

.c-liveComments-list {
  padding: 0 0.625rem 1.25rem;
}

.c-liveComments-list .c-liveComments-card + .c-liveComments-card {
  border-top: solid 1px #cfd3d6;
}

.c-liveComments-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.625rem;
}

.c-liveComments-card--gray {
  background-color: rgba(207, 211, 214, 0.3);
  border-radius: 0.313rem;
  line-height: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-liveComments-card--green {
  background-color: #0bcf65;
  border-radius: 0.313rem;
}

.c-liveComments-card--green .c-liveComments-card__number,
.c-liveComments-card--green .c-liveComments-card__title,
.c-liveComments-card--green .c-liveComments-card__text {
  color: #ffffff;
}

.c-liveComments-card__icons {
  margin-right: 1rem;
}

.c-liveComments-card__number {
  font-size: 0.875rem;
  color: #222222;
  font-weight: 700;
}

.c-liveComments-card__body {
  padding-left: 1rem;
  padding-right: 1rem;
}

.c-liveComments-card__title {
  font-size: 1rem;
  color: #161e24;
  font-weight: 700;
}

.c-liveComments-card__text {
  font-size: 0.875rem;
  color: #1c252c;
}

.c-liveComments-card .c-icon--close-1,
.c-liveComments-card .c-icon--close-white {
  background-size: auto;
  margin-left: auto;
  cursor: pointer;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.c-match-report {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 7.5rem;
}

.c-match-info-card {
  line-height: normal;
  padding-bottom: 1.5rem;
}

.c-match-info-card__label {
  font-size: 0.875rem;
  color: #161e24;
  margin-bottom: 0.5rem;
}

.c-match-info-card__text {
  font-size: 1.125rem;
  color: #161e24;
  font-weight: 700;
}

.c-match-info {
  padding: 1.25rem 0.625rem;
}

.c-match-info__body {
  font-size: 0.875rem;
  color: #1c252c;
}

.c-match-info__title {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.c-button-group-match-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2.5rem;
}

.c-button-group-match-content .c-button + .c-button {
  margin-left: 0.625rem;
}

.c-final-score {
  padding: 2rem 1.25rem 2.625rem;
  text-align: center;
}

.c-final-score__title {
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 0.25rem;
  font-weight: 700;
}

.c-final-score__text {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 1.5rem;
}

.c-teams {
  text-align: center;
}

.c-teams__logo {
  margin-bottom: 1rem;
}

.c-teams__name {
  font-size: 1rem;
  color: #ffffff;
  font-weight: 700;
  text-transform: capitalize;
}

.c-score {
  margin: 1rem 0 0.5rem;
}

.c-score + .c-score {
  margin-top: 1rem;
}

.c-score--small {
  margin-bottom: 1rem;
}

.c-score--small .c-score__title {
  font-size: 0.75rem;
}

.c-score--small .c-score__number {
  width: 1.875rem;
  height: 1.875rem;
}

.c-score__title {
  font-size: 0.875rem;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.c-score__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.c-score__number {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #33414a;
  border: solid 1px #5e707c;
  border-radius: 0.313rem;
  font-size: 1.875rem;
  color: #ffffff;
  font-weight: 700;
  line-height: normal;
  width: 2.5rem;
  height: 2.5rem;
}

.c-score__number + .c-score__number {
  margin-left: 0.625rem;
}

.c-media-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.313rem;
}

.c-media-card__image {
  margin: 0.313rem;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  border: 3px solid transparent;
}

.c-media-card__image:focus {
  border: 3px solid transparent;
}

.c-media-card__image img {
  max-width: none !important;
  height: 150px;
  object-fit: cover;
}

.c-match-report-card__body {
  background-color: #ffffff;
  padding: 1.25rem;
}

.c-match-report-card__title {
  font-size: 2rem;
  color: #1c252c;
  font-weight: normal;
  margin-bottom: 0.75rem;
}

.c-match-report-card__text {
  font-size: 0.875rem;
  color: #1c252c;
  margin-bottom: 1rem;
}

.c-match-report-card__text--bold {
  font-size: 0.875rem;
  font-weight: 700;
}

.c-article .c-button-group {
  padding-top: 2rem;
}

.c-article .c-button-group .c-button + .c-button {
  margin-left: 0.5rem;
}

.c-social-account {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1.25rem;
}

.c-social-account__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-social-account .c-news-switch {
  padding-left: 2.5rem;
  padding-right: 0;
}

.c-social-account .c-social-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  color: #1c252c;
}

.c-social-account .c-social-name .c-icon {
  margin-right: 1.25rem;
}

.c-match-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem;
}

.c-match-content:nth-child(odd) {
  background-color: #ffffff;
}

.c-match-content > .c-button {
  margin-left: auto;
}

.c-match-date-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #33414a;
  min-width: 3.75rem;
}

.c-match-date-time__date {
  font-size: 1.875rem;
  font-weight: 700;
  line-height: normal;
  padding-right: 0.25rem;
}

.c-match-date-time__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 0.75rem;
}

.c-match-date-time__month {
  font-weight: 700;
}

.c-match-teams {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.75rem;
  padding-right: 2.875rem;
  padding-left: 2.875rem;
}

.c-match-teams__name {
  color: #1c252c;
  font-weight: 700;
  width: 11.125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-match-teams__name--right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.c-match-teams__name--right img {
  margin-left: 1.25rem;
}

.c-match-teams__name--left img {
  margin-right: 1.25rem;
}

.c-match-teams__name img {
  width: 1.875rem;
  height: 1.875rem;
}

.c-match-teams__vs {
  min-width: 1.875rem;
  padding: 0 1.5rem;
}

.c-match-stadium {
  font-size: 0.75rem;
  color: #1c252c;
}

.c-player-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.625rem;
}

.c-player-list .c-players {
  width: 20%;
  padding: 0.625rem;
}

.c-players__image {
  margin-bottom: 0.625rem;
}

.c-players__body {
  font-size: 0.875rem;
  color: #1c252c;
}

.c-players__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 0.25rem;
}

.c-players__name {
  font-weight: 700;
  padding-right: 0.5rem;
}

.c-account-setting .c-table {
  width: 100%;
  background-color: #ffffff;
}

.c-account-setting .c-table__edit-row {
  background-color: #f5f5f5;
}

.c-account-setting .c-table td {
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  color: #1c252c;
  border-top: solid 1px #eeeeee;
  vertical-align: top;
}

.c-account-setting .c-table td.c-table__label {
  font-weight: 700;
  width: 7.5rem;
}

.c-account-setting .c-table td.c-table__edit {
  color: #cf0b48;
  text-align: right;
}

.c-social-card-main {
  padding: 0px 0px 1.25rem 0px;
  background-color: #fff;
  border-radius: 0.313rem;
}

.c-social-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  background-color: rgba(238, 238, 238, 0.5);
}

.c-social-card__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.c-social-card__body__left-part {
  padding-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-social-card__body__right-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.c-social-card__image {
  width: 1.25rem;
  height: 1.25rem;
  overflow: hidden;
}

.font-b-i {
  font-weight: 600;
  font-style: italic;
}

@media screen and (max-width: 1320px) {
  .c-media-library__filters__lpart {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .c-media-library__filters .c-form-group select.form-control {
    width: 9rem;
  }
}

@media screen and (max-width: 1200px) {
  .c-media-library__filters .c-form-group {
    margin-top: 0.625rem;
  }
  .c-article .c-form-group__preview {
    padding: 1rem 0.5rem;
  }
  .c-match-teams {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .c-match-teams__vs {
    padding: 0 1rem;
  }
  .c-match-teams__name {
    width: 8.5rem;
  }
  .c-match-teams__name--right img {
    margin-left: 1rem;
  }
  .c-match-teams__name--left img {
    margin-right: 1rem;
  }
  .c-player-list .c-players {
    width: 33.33%;
  }
}

@media screen and (max-width: 768px) {
  .c-media-library__filters {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .c-media-library__filters .c-tabs-button {
    margin-bottom: 0.75rem;
  }
  .c-media-library__filters .c-form-group {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0.625rem;
    width: calc(50% - 0.625rem);
  }
  .c-media-library__filters .c-form-group select.form-control {
    width: 100%;
  }
  .c-media-library__filters__lpart {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .c-media-library__filters__rpart {
    margin: 0.75rem 0 0;
    width: 100%;
  }
  .c-header {
    padding: 0.625rem 0.938rem !important;
  }
}

@media screen and (max-width: 576px) {
  .c-account-setting {
    overflow-x: auto;
  }
  .c-account-setting .c-table td {
    padding: 0.625rem;
  }
  .c-account-setting .c-table td.c-table__label {
    width: auto;
  }
  .c-card-news {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .c-card-news__image {
    width: 100%;
    text-align: center;
  }
  .c-card-news__body {
    padding: 0.625rem;
  }
  .c-match-card--right {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    margin-bottom: 1rem;
  }
  .c-match-card--right .c-match-card__image {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
  .c-match-card--left {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 1rem;
  }
  .c-match-card--left .c-match-card__image {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
  .c-card__header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .c-card__header__left {
    padding-bottom: 0.5rem;
    width: 100%;
  }
  .c-card__header__left--wd-auto {
    width: auto;
  }
  .c-card__header__right {
    margin-left: auto;
  }
  .c-social-account {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .c-social-account .c-news-switch {
    margin-left: auto;
  }
  .c-social-account__left,
  .c-social-account__right {
    width: 100%;
  }
  .c-social-account__left {
    margin-bottom: 0.25rem;
  }
  .c-social-account__right {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .c-media-library__filters .c-tabs-button--add-news {
    margin-bottom: 0;
  }
  .c-media-library__filters .c-tabs-button--add-news .wd-120 {
    width: 6.35rem;
  }
  .c-article .c-form-group {
    padding-top: 1.25rem;
  }
  .c-match-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .c-match-content > .c-button {
    margin: 0.5rem auto 0;
  }
  .c-match-stadium {
    margin-top: 0.5rem;
  }
  .c-match-teams {
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .c-match-teams__vs {
    padding: 0 1rem;
  }
  .c-match-teams__name {
    width: 8.5rem;
  }
  .c-match-teams__name--right img {
    margin-left: 1rem;
  }
  .c-match-teams__name--left img {
    margin-right: 1rem;
  }
  .c-player-list .c-players {
    width: 50%;
  }
}

@media screen and (max-width: 440px) {
  .c-card__body .c-button-group,
  .c-card__footer .c-button-group {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .c-card__body .c-button-group .c-button + .c-button,
  .c-card__footer .c-button-group .c-button + .c-button {
    margin-left: 0;
    margin-top: 0.625rem;
  }
}

@media screen and (min-width: 768px) {
  .c-card__header {
    padding: 1.25rem;
  }
  .c-club-revenue .row > div + div .c-club-revenue-card {
    border-left: solid 1px #cfd3d6;
    padding-top: 0;
  }
  .c-card__body--ptb-15-20,
  .c-card__footer--ptb-15-20 {
    padding: 0.938rem 1.25rem;
  }
  .c-tasks {
    padding-left: 1.25rem;
  }
  .c-card-training__body {
    padding: 1rem 1.625rem;
  }
  .c-news-card {
    padding: 1.25rem;
  }
  .c-news-card__body {
    padding-left: 1.25rem;
  }
  .c-news-card__body__left-part {
    padding-right: 1.25rem;
  }
  .c-news-switch {
    padding-right: 1.25rem;
  }
  .c-media-card {
    padding: 0.875rem;
  }
  .c-liveComments-list {
    padding: 0 1.25rem 1.25rem;
  }
  .c-liveComments-card {
    padding: 1.25rem;
  }
  .c-liveComments-card__icons {
    margin-right: 1.5rem;
  }
  .c-liveComments-card__body {
    padding-left: 1.75rem;
    padding-right: 2.25rem;
  }
  .c-match-report-card__body {
    padding: 1.25rem 1.875rem;
  }
  .c-match-info {
    padding: 1.25rem;
  }
  .c-score {
    margin: 0;
  }
  .c-score--small {
    margin-bottom: 0;
  }
}

.c-form-group {
  margin-bottom: 1.25rem;
}

.c-form-group__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-form-group .form-lable {
  font-size: 1rem;
  color: #1c252c;
  margin-bottom: 0.75rem;
}

.c-form-group .form-control {
  background-color: #ffffff;
  border: 1px solid #cfd3d6;
  border-radius: 0.313rem;
  padding: 0.25rem 0.875rem;
  font-size: 0.875rem;
  color: #1c252c;
  height: 2.5rem;
}

.c-form-group select.form-control {
  background: url(../images/icons/arrow-bottom-pink.svg) no-repeat right 1rem center #ffffff;
  padding-right: 2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.c-form-group textarea.form-control {
  height: 8.125rem;
  resize: none;
}

.c-form-group .we-170 {
  width: 10.625rem;
}

.c-form-group__preview {
  background-color: #f5f5f5;
  border-radius: 0.313rem;
  padding: 2.25rem;
}

.c-form-group__preview .c-icon--mobile {
  margin-left: 1.625rem;
}

.c-switch {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 1.25rem;
  margin-bottom: 0;
}

.c-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.c-switch input:checked + .c-switch__slider {
  background-color: #0bcf65;
}

.c-switch input:checked + .c-switch__slider:before {
  -webkit-transform: translateX(1.25rem);
  transform: translateX(1.25rem);
}

.c-switch input:focus + .c-switch__slider {
  -webkit-box-shadow: 0 0 1px #2196f3;
  box-shadow: 0 0 1px #2196f3;
}

.c-switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cfd3d6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  overflow: hidden;
  /* Rounded sliders */
}

.c-switch__slider:before {
  position: absolute;
  content: '';
  height: 1.125rem;
  width: 1.125rem;
  left: 1px;
  bottom: 1px;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 0 0.25rem 0.25rem rgba(28, 37, 44, 0.3);
  box-shadow: 0 0.25rem 0.25rem rgba(28, 37, 44, 0.3);
}

.c-switch__slider--round {
  border-radius: 0.625rem;
}

.c-switch__slider--round:before {
  border-radius: 50%;
}

.c-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 2rem;
  padding: 0 0.75rem;
}

.c-search .c-icon {
  margin-right: 0.5rem;
  cursor: pointer;
}

.c-search .search-control {
  font-size: 0.875rem;
  color: #1c252c;
  border: 0;
  width: 15.875rem;
  height: 1.875rem;
}

.c-search .search-control:focus {
  outline: 0;
}

@media screen and (max-width: 768px) {
  .c-form-group__preview {
    padding: 1rem 0.5rem;
  }
  .c-form-group__preview .c-icon--mobile {
    margin-left: 1rem;
  }
}

.c-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1.25rem;
}

.c-pagination .pagination {
  margin: 0;
  padding: 0;
}

.c-pagination .pagination .page-item {
  padding: 0 0.313rem;
}

.c-pagination .pagination .page-item.active .page-link {
  border-color: #000000;
  background-color: #000000;
  color: #ffffff;
}

.c-pagination .pagination .page-item .page-link {
  background-color: #ffffff;
  border-radius: 0.313rem;
  border: solid 1px #cfd3d6;
  padding: 0;
  width: 2.25rem;
  height: 2.25rem;
  font-size: 0.875rem;
  color: #050505;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-pagination .c-button {
  height: 2.25rem;
  line-height: normal;
  padding: 0.5rem 1.25rem;
}

@media screen and (max-width: 576px) {
  .c-pagination {
    padding: 0.625rem;
  }
}

.c-tabs-button {
  border-bottom: 0;
}

.c-tabs-button .wd-120 {
  width: 7.5rem;
}

.c-tabs-button .nav-item {
  margin-bottom: 0;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.c-tabs-button .nav-item:first-child .nav-link {
  border-radius: 0.313rem 0 0 0.313rem;
}

.c-tabs-button .nav-item:last-child .nav-link {
  border-radius: 0 0.313rem 0.313rem 0;
}

.c-tabs-button .nav-item .nav-link {
  font-size: 1.1rem;
  color: #1c252c;
  border: 1px solid #cfd3d6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0;
  padding: 0.25rem 0.875rem;
  width: fit-content;
  flex: 1;
  min-width: max-content;
  background-color: transparent;
}

.c-tabs-button .nav-item:first-child {
  border-radius: 0.313rem 0 0 0.313rem;
}

.c-tabs-button .nav-item:last-child {
  border-radius: 0 0.313rem 0.313rem 0;
}

.c-tabs-button .nav-item.active-gray {
  background-color: #33414a !important;
  color: #ffffff !important;
}

.c-tabs-button .nav-item.active-red {
  background-color: #cf0b48 !important;
  color: #ffffff !important;
  border-radius: 5px;
  font-family: Exo-SemiBold;
}

.c-tabs-button .nav-item.active-gray .nav-link {
  color: #ffffff !important;
}

.c-tabs-button .nav-item.active-red .nav-link {
  color: #ffffff !important;
}

.c-tabs-button .nav-item .nav-link.active .c-icon--image {
  background-image: url(../images/icons/image.svg);
}

.c-popup .modal-header {
  border: 0;
  padding: 0 0 1.25rem;
}

.c-popup .modal-header .close {
  padding: 0;
  margin: 0;
  opacity: 1;
}

.c-popup .modal-dialog {
  max-width: 36.25rem;
}

.c-popup .modal-dialog--wd-810 {
  max-width: 50.625rem;
}

.c-popup .modal-dialog--wd-75p {
  max-width: 75%;
}

.c-popup .modal-content {
  border: 0;
  padding: 1.875rem;
}

.c-popup .modal-title {
  font-size: 1.5rem;
  color: #1c252c;
  font-weight: 600;
}

.c-popup .modal-body {
  padding: 0;
}

.c-popup .p-0 {
  padding: 0;
}

.c-popup .b-radius-0 {
  border-radius: 0;
}

.modal-backdrop {
  background-color: #1c252c;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.c-upload-media {
  background-color: #eeeeee;
  border: 1px dashed #4e5d67;
  border-radius: 0.625rem;
  height: 24.375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
}

.c-upload-media--hi-300 {
  height: 17.1rem;
}

.c-upload-media--hi-200 {
  height: 12.75rem;
}
.c-upload-media--hi-auto {
  height: auto;
}

.c-upload-media--ptb-22 {
  padding: 1.375rem 0;
}

.c-upload-media__drag-image {
  font-size: 1.5rem;
  color: rgba(34, 34, 34, 0.5);
  margin-bottom: 2.625rem;
}

.c-upload-media__drag-image--mb-18 {
  margin-bottom: 1.125rem;
}

.c-upload-media__text {
  font-size: 0.875rem;
  color: rgba(34, 34, 34, 0.5);
  margin-bottom: 0.5rem;
}

.c-upload-media__body--row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-upload-media__body--row .c-upload-media__text {
  margin: 0 0.5rem 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-upload-media .c-button {
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
}

.c-add-event .mb-20 {
  margin-bottom: 1.25rem;
}

.c-add-event .c-button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-add-event .c-button-group .c-button + .c-button {
  margin-left: 0.75rem;
}

.c-image-details__body {
  padding: 1.875rem;
  padding-top: 0;
}

.c-image-details .c-button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 1.25rem;
}

.c-image-details .c-button-group .c-button + .c-button {
  margin-left: 0.75rem;
}

.c-add-player .c-button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-add-player .c-button-group .c-button + .c-button {
  margin-left: 0.625rem;
}

@media screen and (max-width: 1320px) {
  .c-image-details__image {
    max-width: 38.5rem;
  }
}

@media screen and (max-width: 1200px) {
  .c-upload-media__drag-image {
    font-size: 1.125rem;
  }
  .c-upload-media__button .c-button + .c-button {
    margin-top: 0.5rem;
  }
  .c-image-details__image {
    max-width: 28.25rem;
  }
}

@media screen and (max-width: 768px) {
  .c-popup .modal-dialog--wd-810 {
    max-width: 36.25rem;
  }
  .c-image-details__image {
    max-width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 576px) {
  .c-popup .modal-content {
    padding: 1rem;
  }
  .c-popup .modal-dialog--wd-75p,
  .c-popup .modal-dialog--wd-810 {
    max-width: inherit;
  }
  .c-image-details__body {
    padding: 1rem;
  }
}

.c-header {
  background-color: #1c252c;
  padding: 0.625rem 1.938rem;
  z-index: 9;
  position: relative;
}
.c-header-master {
  background-color: #ffffff;
}

.c-header__rpart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
}

.c-header__rpart > div {
  margin-left: 1.75rem;
  line-height: normal;
}

.c-header__rpart .c-icon--hamburger {
  margin-left: 1.25rem;
  cursor: pointer;
}

.c-header .c-mail,
.c-header .c-notification {
  position: relative;
}

.c-header .c-mail__notify,
.c-header .c-notification__notify {
  position: absolute;
  top: -0.375rem;
  right: -0.625rem;
  background-color: #cf0b48;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  color: #ffffff;
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.688rem;
}

@media screen and (max-width: 576px) {
  .c-logo img {
    max-width: 6.25rem;
  }
}

@media screen and (min-width: 768px) {
  .c-header__rpart > div {
    margin-left: 2.5rem;
  }
}

@media screen and (min-width: 992px) {
  .c-header__rpart .c-icon--hamburger {
    display: none;
  }
}

.c-footer {
  padding: 0.625rem 0;
  background-color: #1c252c;
  line-height: normal;
}

.c-footer__logo {
  opacity: 0.5;
}

.c-footer__rpart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.c-footer .c-copyright {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.2);
}

.c-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 0.75rem 0 0;
  margin: 0;
  list-style: none;
}

.c-social li {
  padding: 0 0.5rem;
}

@media screen and (max-width: 576px) {
  .c-footer__logo {
    margin: 0 auto 0.75rem;
    display: block;
  }
  .c-footer__rpart {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.c-login {
  background: url(../images/gdx-logo-login.svg) no-repeat left -50% center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 2rem;
  min-height: calc(100vh - 9rem);
}

.c-login--footer {
  min-height: calc(100vh - 7.5rem);
}

.c-login__cbox {
  width: calc(100% - 1.875rem);
  margin: 0 auto;
}

.c-login__title {
  font-size: 1.625rem;
  color: #cf0b48;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.875rem;
}

.c-login__text {
  font-size: 0.875rem;
  text-align: center;
  max-width: 18.5rem;
  margin: 0 auto 1.875rem;
}

.c-login__button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 1.875rem;
}

.c-login .c-button {
  min-width: 18.375rem;
  margin-bottom: 3.125rem;
}

.c-login .c-login-link {
  font-size: 0.875rem;
  color: #cf0b48;
  font-weight: 600;
}

.c-login .c-form-group .form-control {
  height: 3.125rem;
}

@media screen and (min-width: 768px) {
  .c-login {
    padding-top: 5rem;
  }
  .c-login__cbox {
    width: 27.5rem;
    margin: 0 auto;
  }
}

.modal-content {
  min-width: 30%;
  max-width: 350px;
  top: 40%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.2);
}

.c-carousal-card {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0.125rem 0.25rem #dddddd;
  box-shadow: 0 0.125rem 0.25rem #dddddd;
  border-radius: 0.313rem;
}

.c-carousal__header {
  border-bottom: solid 1px rgba(207, 211, 214, 0.5);
  padding: 0.625rem 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.sticky {
  position: fixed !important;
  width: 78.8%;
  background-color: #fff;
}
.sticky-error {
  position: fixed !important;
  width: 78.85%;
  background-color: transparent;
  margin-top: 44px;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: large;
}

.c-carousal__header__lpart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 18px;
}

.c-carousal__header__rpart {
  margin-left: auto;
}

.v-center {
  align-items: center;
}

.v-flex-start{
  align-items: flex-start;
}

.v-flex-end{
  align-items: flex-end;
}

.h-center {
  justify-content: center;
}

.h-space-bet{
  justify-content: space-between;
}

.h-flex-end{
  justify-content: flex-end;
}

.h-flex-start{
  justify-content: flex-start;
}

.carousal-table-title {
  font-size: 12px;
  color: #616e77;
  display: flex;
  flex-direction: row;
}

.carousal-table-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.font-b {
  font-weight: bolder;
}
@media screen and (max-width: 1320px) {
  .c-carousal__header__lpart {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .c-carousal__header {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .c-carousal__header__lpart {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .c-carousal__header__rpart {
    margin: 0.75rem 0 0;
    width: 100%;
  }
}

.content-tabs {
  background-color: white;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 6px;
}
.content-tabs-w {
  width: -webkit-fill-available;
}
.content-tabs-active {
  padding: 12px 19px !important;
  border-radius: 0.313rem !important;
  border: none !important;
}

.drop-display:hover {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  color: #fff !important;
  justify-content: center;
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.drop-item-img-bg {
  border-radius: 10px;
  flex: 1;
  width: 100%;
}

.carousal-edit-title {
  justify-content: center;
  display: flex;
  color: #cf0b48;
  font-size: 25px;
  align-items: flex-end;
}

.carousal-edit-drop-box {
  justify-content: center;
  display: flex;
  margin-top: 40px;
}

.img-testimonials-list {
  height: 25px;
  width: 25px;
  border-radius: 25px;
}

.faqs-edit-box {
  justify-content: center;
  display: flex;
  margin-top: 40px;
}

.icon-edit-text {
  font-size: 15px;
  text-decoration: underline;
  word-spacing: -1px;
}

.img_preview-testimonials {
  height: 60px;
  width: 60px;
  border-radius: 5px;
}

.img_preview-testimonials-inner {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.img_preview-benefits {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.inline-btn-input {
  align-items: flex-end;
  display: flex;
  margin-bottom: 1.25rem;
}

.c-pointer {
  cursor: pointer;
}

.c-m-card-body {
  background-color: #fff;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.c-m-btn-remove {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.flex {
  display: flex;
}
.f-0-02{
  flex: 0.02;
}
.f-0-05{
  flex: 0.05;
}
.f-0-1{
  flex: 0.1;
}

.f-0-15{
  flex: 0.15;
}
.f-0-2{
  flex: 0.2;
}
.f-0-25{
  flex: 0.25;
}
.f-0-3 {
  flex: 0.3;
}
.f-0-4 {
  flex: 0.4;
}
.f-0-5 {
  flex: 0.5;
}
.f-0-6 {
  flex: 0.6;
}
.f-0-67 {
  flex: 0.67;
}
.f-0-7 {
  flex: 0.7;
}
.f-0-8 {
  flex: 0.8;
}
.f-0-85 {
  flex: 0.85;
}
.f-1 {
  flex: 1;
}
.carousal-edit-desc {
  justify-content: center;
  display: flex;
  color: #1c252c;
  font-size: 14px;
  align-items: flex-end;
  font-family: Exo-Regular;
}
.summary-title {
  font-size: 20px;
  color: #4e5d67;
}
.summary-desc {
  font-size: 14px;
  color: #4e5d67;
}
.summary-list-title {
  font-size: 16px;
  color: #cf0b48;
}
.summary-detail-desc {
  font-size: 16px;
  color: #1c252c;
  justify-content: flex-end;
}
.bg-null {
  background: none;
}
.border-null {
  border: none;
}
.min-w-150{
  min-width: 150px;
}
.max-w-130{
  max-width: 130px;
}
.w-100{
  width: 100%;
}
.w-80{
  width: 80%;
}
.w-46{
  width: 46%;
}
.w-240px{
  width: 240px;
}
.min-w-60{
  min-width: 60px;
}
.h-100 {
  height: 100%;
}
.h-45 {
  height: 45%;
}
.h-60px{
  height: 60px;
}
.h-auto {
  height: auto;
}
.h-fit-content{
  height: fit-content;
}
.min-h-300{
  min-height: 300px;
}
.min-h-150{
  min-height: 150px;
}
.max-h-600{
  max-height: 600px;
}
.img_preview-match-content {
  height: 28px;
  width: 28px;
  border-radius: 50%;
}
.fn-style-i {
  font-style: italic;
}
.disable-div {
  pointer-events: none;
  opacity: 0.7;
}
.title-color {
  color: #1c252c;
}
.my-detail-outer {
  padding: 15px 25px;
  font-size: 16px;
  align-items: flex-start;
  border-bottom: 1px solid #eeeeee;
}
.img_preview-player {
  height: 152px;
  width: 192px;
  border-radius: 5px;
  background-color: #33414A;
}
.player-name-input-we-l{
  min-width: 50px;
  max-width: 95%
}
.player-name-input-we-r{
  min-width: 50px;
  max-width: 100%
}
.player-name-input-we-f{
  min-width: 50px;
  max-width: 100%
}
.v-end {
  align-items: flex-end;
}
.flex-d-column{
  flex-direction: column
}
.flex-d-row{
  flex-direction: row
}
.h-70{
  height: 70% !important
}
.img_preview-match-content-detail {
  height: 65px;
  width: 65px;
  border-radius: 50%
}
.final-score-box{
  background: #33414A;
  border: 1px solid #5E707C;
  height: 40px;
  width: 40px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
}
.penaltie-box{
  background: #33414A  ; 
  border: 1px solid #5E707C  ;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 5px;
  color: #fff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.final-score-error{
  display: flex !important;
  justify-content: center
}
.final-score-outer{
  background-color: #1C252C !important;
  color: #ffffff
}
.fn-11{
  font-size:11px !important
}
.fn-12{
  font-size:12px !important
}
.fn-14{
  font-size: 14px
}
.fn-16{
  font-size:16px !important
}
.fn-18{
  font-size: 18px
}
.fn-20{
  font-size:20px !important
}
.fn-24{
  font-size:24px !important
}
.fn-26{
  font-size:26px !important
}
.fn-30{
  font-size:30px !important
}
.bg-white{
  background-color: #fff
}
.bg-gray-dark{
  background-color: #CFD3D6
}
.bg-light-green{
  background-color: #0BCF65
}
.width-auto{
  width: auto !important;
}
.height-fit-c{
  height: fit-content
}
.left-redius-none{
  border-radius: 0.313rem 0px 0px 0.313rem !important;
}
.right-redius-none{
  border-radius: 0px 0.313rem 0.313rem 0px !important;
}
.flex-1{
  display: flex;
  flex:1
}
.flex-1-center-v{
  display: flex;
  flex:1;
  align-items: center
}
.flex-1-center-h{
  display: flex;
  flex:1;
  justify-content: center;
}
.flex-1-center{
  display: flex;
  flex:1;
  justify-content: center;
  align-items: center
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center
}
.flex-center-v{
  display: flex;
  align-items: center
}
.flex-center-h{
  display: flex;
  justify-content: center;
}
.card-match-content{
  width: 100%;
  padding: 0.625rem 1.25rem;
}
.no-border{
  border: none
}
.no-border-b{
  border-bottom: none
}
.rolefrom-inner{
  background-color: #F6F6F6;
   padding: 0px 20px
}
.line-h-1{
  line-height: 1px
}
.player-card-outer{
  background-color: #EEEEEE; 
  flex-wrap: wrap;
}
.bg-gray{
  background-color: #EEEEEE; 
}
.bg-low-black{
  background-color: #33414A;
}
.bg-red{
  background-color: red;
}
.club-name-outer{
  font-family: Exo-Bold;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center
}
.f-color-white{
  color: #fff
}
.f-color-green{
  color: #0bcf65
}
.f-color-red{
  color: #CF0B48
}
.visible-50{
  opacity:0.5
}
.preview-image-inner{
  border-radius: 20px;
  border: 1px solid #fff
}
.border-light-gray{
  border: 1px solid #CFD3D6;
}
.mt-70{
  margin-top: 70px
}
.mt-40{
  margin-top: 40px
}
.br-10{
  border-radius: 10px;
}
.add-club-outer{
  border-radius: 10px;
  background-color: #fff;
}
.add-club-time-outer{
  align-items: flex-end;
  flex: 0.5;
  font-size: 0.7rem;
  color: #4E5D67;
}
.c-revenue-highest--radius{
  border-radius: 0rem 0rem 0.313rem 0.313rem
}
.c-total-club-body{
  padding: 0.600rem 1.25rem;
  max-height: 150px;
}
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}
.recharts-yAxis {
  transform: translate(4%, -5%);  
}
.recharts-tooltip-wrapper-left{
  left:58px !important
}
.recharts-tooltip-wrapper-right{
  left:-35px !important
}
.tooltip-box{
  background: #33414A;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-right: 20px;
}
.tooltip-box::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 35%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #33414A transparent transparent transparent;
}
.c-club-revenue-card .c-icon--triangle--up--small {
  width: 0;
  height: 0;
  border-left: 0.3rem solid transparent;
  border-right: 0.3rem solid transparent;
  border-bottom: 0.3rem solid #0bcf65;
}
.c-club-revenue-card .c-icon--triangle--down--small {
  width: 0;
  height: 0;
  border-left: 0.3rem solid transparent;
  border-right: 0.3rem solid transparent;
  border-top: 0.3rem solid #cf0b48;
}
.chart-font{
  font-size: 20px;
}
.chart-title-font{
  font-size: 14px;
}
@media screen and (max-width: 1200px) {
  .chart-font{
    font-size: 13px;
  }
  .chart-title-font{
    font-size: 10px;
  }
}
.z-index-1{z-index: 1}
.video-preview-border-r{
  border-radius: 15px;
  overflow: hidden;
}
.tab-border{
  border-radius: 0.313rem !important;
}
.score-box-outer{
  background:linear-gradient(180deg, #1C252C 50%,#efefef 53%, #33414A 50%);
  width: 40px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
}
.scroll-y{
  overflow-y: auto
}
.possition-fix{
  position: fixed
}
.row-default-m{
  margin-left: -15px;
  margin-right: -15px;
}
.disabled-link {
  pointer-events: none;
}
.widget-club-logo{
  border-radius: 50%;
   width: inherit
}
;
