.c-card {
    box-shadow: 0 0.125rem 0.25rem $gray-dd;
    border-radius: 0.313rem;
    margin-bottom: 1.25rem;

    &--dark {
        .c-card__header {
            background-color: $black-33;
            padding: 0.625rem 1.25rem;

            &__right {
                .c-button + .c-button { margin-left: 0.625rem; }
            }
            &__title-1 {
                color: $white;
                @include fs-18;
                font-style: normal;
            }
        }
        .c-card__body {
            background-color: $black-2c;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        border-radius: 0.313rem 0.313rem 0 0;
        padding: 0.625rem;

        &--align-right {
            justify-content: flex-end;
        }

        &.--ptb-10 {
            padding-top: 0.625rem;
            padding-bottom: 0.625rem;
        }

        .--min-h50 { min-height: 3.125rem; }

        &__title {
            display: flex;
            align-items: center;

            &__icons { 
                width: 1.875rem;
                height: 1.875rem;
                display: inline-flex;
                margin-right: 0.625rem; 
                border-radius: 50%;
            }

            .--bold {
                font-weight: 700;
                margin: 0 0.25rem;
            }
        }

        &__title-1 {
            @include fs-16;
            font-weight: 700;
            font-style: italic;
        }

        &__left {
            @include fs-14;
            color: $black-2c;
        }

        &__right {
            display: flex;
            align-items: center;
            
            .c-button + .c-button { margin-left: 0.625rem; }

            .c-icon {
                cursor: pointer;
                & + .c-icon { margin-left: 2rem; }
            }
        }
    }

    &__body,
    &__footer {
        background-color: $gray-f5;
        border-radius:  0 0 0.313rem 0.313rem;

        &--mptb-10 {
            padding: 0.625rem;
        }

        .c-button-group {
            display: flex;
            .c-button + .c-button {
                margin-left: 0.625rem;
            }
        }
    }

    &__footer {
        background-color: $white;
    }
}


// News story card
.c-card-news {
    display: flex;

    .mt-auto { margin-top: auto; }

    &__image {
        border-radius: 0.313rem 0 0 0.313rem;
    }
    &__body {
        width: 100%;
        padding: 1rem 1.625rem;
        display: flex;
        flex-direction: column;
    }
    &__title {
        @include fs-16;
        color: $black-2c;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }
    &__text {
        @include fs-12;
        color: $black-33;
        margin-bottom: 0;
    }
    &__text-muted {
        @include fs-12;
        color: rgba($black-33, 0.5);
    }
}


// Training card
.c-card-training {
    display: flex;

    &__image {}
    &__body {
        width: 100%;
        padding: 1rem;
    }
    &__title {
        @include fs-16;
        color: $black-2c;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }
    &__text {
        @include fs-12;
        color: $black-33;
        margin-bottom: 0.5rem;
    }
    &__time {
        display: flex;
        align-items: center;
        @include fs-12;
        color: $black-33;
        font-weight: 700;
        margin-bottom: 0.5rem;
        .c-icon { margin-right: 0.5rem; }
    }
    &__others {
        display: flex;
        margin-bottom: 0;
    }
    &__progress {
        display: inline-flex;
        align-items: center;
        @include fs-14;
        color: $black-2c;
        margin-right: 20px;
        
        .c-icon { margin-right: 0.25rem; }
    }
}


.c-tasks-list {}

.c-tasks {
    padding: 0.875rem 0.625rem;
    @include fs-14;
    display: flex;
    justify-content: space-between;
    background-color: $white;

    &:nth-child(odd) {
        background-color: rgba($gray-ee, 0.5);
    }

    &__title {
        font-weight: 700;
        color: $pink;
    }
    &__text { color: $black-33; }
    
    .c-button { padding: 0.25rem 0.75rem; }

    &__left { line-height: normal; }
    &__right {}
}

.c-traffic-footer > div.row {
    > div + div {
        border-left: solid 1px $gray-cf;
    }
}
.c-traffic-data {
    padding: 0.625rem 1.25rem;
    color: $black-2c;
    display: flex;
    justify-content: space-between;

    &__text {
        @include fs-14;
        max-width: 3.4rem;
    }
    &__number {
        @include fs-30;
    }
}

.c-revenue-content {
    padding: 1.25rem 0.625rem 0.625rem;

    &__title {
        @include fs-16;
        color: $black-2c;
        font-weight: 700;
        font-style: italic;
        margin-bottom: 0.625rem;
    }
    &__list {}
}

.c-revenue-item {
    display: flex;
    justify-content: space-between;
    background-color: $white;
    padding: 0.375rem 0.625rem;

    &:nth-child(odd) {
        background-color: rgba($gray-ee, 0.5);
    }

    &__text,
    &__price {
        @include fs-14;
    }
    &__text {
        color: $pink;
    }
    &__price {
        text-align: right;
        font-weight: 700;
        color: $black-2c;
    }
}


.c-club-revenue {
    padding: 1.5rem;
    .row > div + div .c-club-revenue-card {
        //border-left: solid 1px $gray-cf;
        padding-top: 1rem;
    }
}

.c-club-revenue-card {
    text-align: center;
    color: $black-2c;
    line-height: normal;

    .c-icon {
        &--triangle {
            &--up {
                width: 0;
                height: 0;
                border-left: 0.5rem solid transparent;
                border-right: 0.5rem solid transparent;
                border-bottom: 0.5rem solid $green;
            }
            &--down {
                width: 0;
                height: 0;
                border-left: 0.5rem solid transparent;
                border-right: 0.5rem solid transparent;
                border-top: 0.5rem solid $pink;
            }
        }
    }
    &__icons {}
    &__price {
        @include fs-40;
        font-weight: 700;
    }
    &__text {
        @include fs-14;
    }
}


.c-sponsored {
    &__header {
        &__body { line-height: normal; }
        &__title {
            @include fs-16;
            font-weight: 700;
            color: $black-2c;
        }
        &__text {
            @include fs-12;
            color: rgba($black-2c, 0.5)
        }
    }
    &__image {
        padding: 0 0.25rem 0.25rem;
    }
}

.c-match {
    background-color: $black-33;
    padding: 1.25rem;
}

.c-match-card {
    display: flex;
    align-items: center;

    &--right {
        justify-content: flex-end;
        .c-match-card__image { margin-left: 2.5rem; }    
    }

    &--left {
        .c-match-card__image { margin-right: 2.5rem; }    
    }

    &__text {
        @include fs-12;
        color: $white;
    }
    &__image {
        background-color: $white;
        border: 1px solid $gray-dd;
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.c-match-versus {
    text-align: center;
    color: $white;

    &__date {
        @include fs-18;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }
    &__time {
        @include fs-12;
    }
}


// News list page

.c-news-list {
    .c-news-card:nth-child(odd) {
        background-color: rgba($gray-ee, 0.5);
    }
}

.c-news-card {
    display: flex;
    padding: 1rem;
    background-color: $white;

    &__title {
        @include fs-16;
        color: $pink;
        font-weight: 700;
        margin-bottom: 0;

        a { 
            color: $pink; 
            text-decoration: underline;
        }
    }
    &__text {
        @include fs-12;
        color: $black-33;
        margin-bottom: 0;
    }
    &__date {
        @include fs-12;
        color: rgba($black-33, 0.5);
    }

    &__image {
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 0.313rem;
        overflow: hidden;
    }
    &__body {
        display: flex;
        justify-content: space-between;
        padding-left: 1rem;
        width: 100%;

        &__left-part {
            padding-right: 1rem;
        }
        &__right-part {
            display: flex;
            justify-content: flex-end;

            .c-icon--more { margin-top: 1.375rem; }
        }
    }
}

.c-news-switch {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem 0 0;

    &__label {
        @include fs-12;
        color: $black-33;
        margin-bottom: 0.25rem;
    }

    &--hr {
        padding-right: 0;
        align-items: flex-start;
        flex-direction: row;
        margin-bottom: 1.25rem;

        .c-switch { margin-right: 0.5rem; }
    }
}

//  Media Library
.c-media-library {
    background-color: $white;
    box-shadow: 0 0.125rem 0.25rem $gray-dd;
    border-radius: 0.313rem;

    &__filters {
        border-bottom: solid 1px rgba($gray-cf, 0.5);
        padding: 0.625rem; 
        display: flex;

        &__lpart {
            display: flex;
        }
        &__rpart { margin-left: auto; }

        .c-form-group { 
            margin-left: 0.625rem; 
            
            select.form-control {
                width: 11.25rem;
                height: 1.875rem !important;
            }
        }
    }

    &__body {
        padding: 1.25rem;

        &--p-14 {
            padding: 0.875rem;
        }
    }

    .c-tabs-button--add-news .nav-item .nav-link {
        height: 2.5rem;
    }
}


.c-liveComments-list {
    padding: 0 0.625rem 1.25rem;

    .c-liveComments-card + .c-liveComments-card { border-top: solid 1px #CFD3D6; }
}


.c-liveComments-card {
    display: flex;
    padding: 0.625rem;

    &--gray {
        background-color: rgba($gray-cf, 0.3);
        border-radius: 0.313rem;
        line-height: normal;
        align-items: center;
    }

    &--green {
        background-color: #0BCF65;
        border-radius: 0.313rem;

        .c-liveComments-card__number,
        .c-liveComments-card__title, 
        .c-liveComments-card__text { color: $white; }
    }

    &__icons {        
        margin-right: 1rem;
    }
    &__number {
        @include fs-14;
        color: $black-22;
        font-weight: 700;
    }
    &__body {
        padding-left: 1rem;
        padding-right: 1rem;
    }    
    &__title {
        @include fs-16;
        color: #161E24;
        font-weight: 700;
    }
    &__text {
        @include fs-14;
        color: #1C252C;
    }

    .c-icon--close-1,
    .c-icon--close-white { 
        background-size: auto;
        margin-left: auto; 
        cursor: pointer;
        flex: none;
    }
}

// Match report
.c-match-report {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 7.5rem;
}


// Match info
.c-match-info-card {
    line-height: normal;
    padding-bottom: 1.5rem;

    &__label {
        @include fs-14;
        color: #161E24;
        margin-bottom: 0.5rem;
    }
    &__text {
        @include fs-18;
        color: #161E24;
        font-weight: 700;
    }
}

.c-match-info {
    padding: 1.25rem 0.625rem;

    &__body {
        @include fs-14;
        color: #1C252C;
    }
    &__title {
        font-weight: 700;
        margin-bottom: 0.5rem;
    }
    &__text {}
}


.c-button-group-match-content {
    display: flex;
    margin-top: 2.5rem;

    .c-button + .c-button {
        margin-left: 0.625rem;
    }
}


// Final score
.c-final-score {
    padding: 2rem 1.25rem 2.625rem;
    text-align: center;

    &__title {
        @include fs-24;
        color: $white;
        margin-bottom: 0.25rem;
        font-weight: 700;
    }
    &__text {
        @include fs-14;
        color: rgba($white, 0.5);
        margin-bottom: 1.5rem;
    }
}

.c-teams {
    text-align: center;

    &__logo { margin-bottom: 1rem; }
    &__name {
        @include fs-16;
        color: $white;
        font-weight: 700;
        text-transform: capitalize;
    }
}

.c-score {
    margin: 1rem 0 0.5rem;

    & + .c-score { margin-top: 1rem; }

    &--small {
        margin-bottom: 1rem;

        .c-score__title {
            @include fs-12;
        }
        .c-score__number {
            width: 1.875rem;
            height: 1.875rem;
        }
    }

    &__title {
        @include fs-14;
        color: $white;
        margin-bottom: 0.5rem;
    }
    &__body {
        display: flex;
        justify-content: center;
    }
    &__number {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: $black-33;
        border: solid 1px #5E707C;
        border-radius: 0.313rem;
        @include fs-30;
        color: $white;
        font-weight: 700;
        line-height: normal;
        width: 2.5rem;
        height: 2.5rem;

        & + .c-score__number { margin-left: 0.625rem; }
    }
}


// Media part
.c-media-card {
    display: flex;
    flex-wrap: wrap;
    padding: 0.313rem;

    &__image {
        margin: 0.313rem;
        cursor: pointer;
    }
}


.c-match-report-card {
    &__image {}
    &__body {
        background-color: $white;
        padding: 1.25rem;
    }
    &__title {
        @include fs-32;
        color: $black-2c;
        font-weight: normal;
        margin-bottom: 0.75rem;
    }
    &__text {
        @include fs-14;
        color: $black-2c;
        margin-bottom: 1rem;

        &--bold {
            @include fs-14;
            font-weight: 700;
        }
    }
}

// Add new article
.c-article {
    .c-button-group {
        padding-top: 2rem;
        .c-button + .c-button {
            margin-left: 0.5rem;
        }
    }
}


//Social account
.c-social-account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem;

    &__left {}
    &__right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .c-news-switch {
        padding-left: 2.5rem;
        padding-right: 0;
    }

    .c-social-name {
        display: flex;
        align-items: center;
        @include fs-16;
        color: $black-2c;

        .c-icon {
            margin-right: 1.25rem;
        }
    }
}


//Match Content
.c-match-content-list {}

.c-match-content {
    display: flex;
    align-items: center;
    padding: 1rem;

    &:nth-child(odd) {
        background-color: $white;
    }

    > .c-button { margin-left: auto; }
}

.c-match-date-time {
    display: flex;
    color: $black-33;
    min-width: 3.75rem;

    &__date {
        @include fs-30;
        font-weight: 700;
        line-height: normal;
        padding-right: 0.25rem;
    }
    &__body {
        display: flex;
        flex-direction: column;
        @include fs-12;
    }
    &__month {
        font-weight: 700;
    }
    &__day {}
}

.c-match-teams {
    display: flex;
    align-items: center;
    @include fs-12;
    padding-right: 2.875rem;
    padding-left: 2.875rem;

    &__name {
        color: $black-2c;
        font-weight: 700;
        width: 11.125rem;
        display: flex;
        align-items: center;

        &--right {
            justify-content: flex-end;
            img {
                margin-left: 1.25rem;
            }
        }

        &--left img {
            margin-right: 1.25rem;
        }

        img {
            width: 1.875rem;
            height: 1.875rem;
        }
    }
    &__vs {
        min-width: 1.875rem;
        padding: 0 1.5rem;
    }
}

.c-match-stadium {
    @include fs-12;
    color: $black-2c;

    &__name {}
    &__time {}
}


// Player managment
.c-player-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0.625rem;

    .c-players {
        width: 20%;
        padding: 0.625rem;
    }
}

.c-players {
    &__image {
        margin-bottom: 0.625rem;
    }
    &__body {
        @include fs-14;
        color: #1C252C;
    }
    &__title {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.25rem;
    }
    &__name {
        font-weight: 700;
        padding-right: 0.5rem;
    }
    &__feaild {}
    &__number {}
}


//Account setting
.c-account-setting {
    .c-table {
        width: 100%;
        background-color: $white;

        &__edit-row {
            background-color: $gray-f5;
        }
        td {
            padding: 1rem 1.25rem;
            @include fs-14;
            color: $black-2c;
            border-top: solid 1px $gray-ee;
            vertical-align: top;

            &.c-table__label {
                font-weight: 700;
                width: 7.5rem;
            }
            &.c-table__text {}
            &.c-table__edit {
                color: $pink;
                text-align: right;
            }
        }
    }
}


// Extra large screen / wide desktop
@media screen and (max-width: 1320px) {
    .c-media-library__filters {
        &__lpart { flex-wrap: wrap; }   
        .c-form-group {
            select.form-control {
                width: 9rem;
            }
        }    
    }
}

// Extra large screen / wide desktop
@media screen and (max-width: 1200px) {
    .c-media-library__filters .c-form-group {
        margin-top: 0.625rem;
    }

    // Add new article
    .c-article {
        .c-form-group {
            &__preview { padding: 1rem 0.5rem; }
        }
    }

    //Match Content
    .c-match-teams {
        padding-right: 1rem;
        padding-left: 1rem;

        &__vs { padding: 0 1rem; }

        &__name {
            width: 8.5rem;
            &--right img { margin-left: 1rem; }
            &--left img { margin-right: 1rem; }
        }
    }

    // Player managment
    .c-player-list .c-players {
        width: 33.33%;
    }
}

// Medium screen / tablet
@media screen and (max-width: 768px) {
    .c-media-library {
        &__filters { 
            flex-wrap: wrap; 

            .c-tabs-button {
                margin-bottom: 0.75rem;
            }

            .c-form-group {
                margin-top: 0;
                margin-left: 0;
                margin-right: 0.625rem;
                width: calc(50% - 0.625rem);

                select.form-control { width: 100%; }
            }

            &__lpart {
                flex-wrap: wrap;
            }
            &__rpart {
                margin: 0.75rem 0 0;
                width: 100%;
            }
        }

    }
}

// Small screen / phone
@media screen and (max-width: 576px) {
    //Account setting
    .c-account-setting {
        overflow-x: auto;

        .c-table td {
            padding: 0.625rem;

            &.c-table__label { width: auto; }
        }
    }

    .c-card-news {
        flex-wrap: wrap;
        &__image {
            width: 100%;
            text-align: center;
        }
        &__body {
            padding: 0.625rem;
        }
    }

    .c-match-card {
        &--right {
            justify-content: center;
            flex-direction: column-reverse;
            margin-bottom: 1rem;
    
            .c-match-card__image { margin-left: 0; margin-bottom: 0.5rem; }    
        }
    
        &--left {
            justify-content: center;
            flex-direction: column;
            margin-top: 1rem;
            
            .c-match-card__image { margin-right: 0; margin-bottom: 0.5rem }    
        }
    }    

    .c-card {
        &__header {
            flex-direction: column;

            &__left {
                padding-bottom: 0.5rem;
                width: 100%;

                &--wd-auto { width: auto; }
            }

            &__right { margin-left: auto; }
        }
    }

    //Social account
    .c-social-account {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .c-news-switch {
            margin-left: auto;
        }

        &__left,
        &__right {
            width: 100%;
        }

        &__left {
            margin-bottom: 0.25rem;
        }
        &__right {
            align-items: flex-end;
        }
    }

    //  Media Library
    .c-media-library {
        &__filters {
            .c-tabs-button--add-news { 
                margin-bottom: 0; 
                .wd-120 { width: 6.35rem; }
            }
        }
    }

    // Add new article
    .c-article {
        .c-form-group {
            padding-top: 1.25rem;
        }
    }

    //Match Content
    .c-match-content {
        flex-direction: column;

        > .c-button {
            margin: 0.5rem auto 0;
        }
    }
    
    .c-match-stadium { margin-top: 0.5rem; }

    .c-match-teams {
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        justify-content: center;

        &__vs { padding: 0 1rem; }

        &__name {
            width: 8.5rem;
            &--right img { margin-left: 1rem; }
            &--left img { margin-right: 1rem; }
        }
        
    }

    // Player managment
    .c-player-list .c-players {
        width: 50%;
    }

}

// Small screen / phone
@media screen and (max-width: 440px) {
    .c-card {
        &__body,
        &__footer {
            .c-button-group {
                flex-direction: column;

                .c-button + .c-button {
                    margin-left: 0;
                    margin-top: 0.625rem;
                }
            }
        }
    }
}


// Small screen / phone
@media screen and (min-width: 576px) {
    
}

// Medium screen / tablet
@media screen and (min-width: 768px) {
    .c-club-revenue {
        .row > div + div .c-club-revenue-card {
            border-left: solid 1px $gray-cf;
            padding-top: 0;
        }
    }

    .c-card { 
        &__header {
            padding: 1.25rem;
        }
        &__body,
        &__footer {
            &--ptb-15-20 {
                padding: 0.938rem 1.25rem;
            }
        }
    }

    .c-tasks {
        padding-left: 1.25rem;
    }    

    // Training card
    .c-card-training {
        &__body { padding: 1rem 1.625rem; }
    }

    .c-news-card {
        padding: 1.25rem;

        &__body { 
            padding-left: 1.25rem; 
            &__left-part {
                padding-right: 1.25rem;
            }
        }
    }

    .c-news-switch { padding-right: 1.25rem; }

    // Media part
    .c-media-card {
        padding: 0.875rem;
    }

    .c-liveComments-list {
        padding: 0 1.25rem 1.25rem;
    }    

    .c-liveComments-card {
        padding: 1.25rem; 

        &__icons {
            margin-right: 1.5rem;
        }
        &__body {
            padding-left: 1.75rem;
            padding-right: 2.25rem;
        }
    }    

    .c-match-report-card {
        &__body {
            padding: 1.25rem 1.875rem;
        }
    }    

    // Match info
    .c-match-info {
        padding: 1.25rem;
    }    

    .c-score {
        margin: 0;
    
        &--small {
            margin-bottom: 0;
        }    
    }    
}


// Large screen / desktop
@media screen and (min-width: 992px) {
    
}

// Extra large screen / wide desktop
@media screen and (min-width: 1200px) {
    
}