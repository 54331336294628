.c-tabs-button {
    border-bottom: 0;
    
    .wd-120 { width: 7.5rem; }

    .nav-item {
        margin-bottom: 0;

        &:first-child .nav-link { border-radius: 0.313rem 0 0 0.313rem; }
        &:last-child .nav-link { border-radius: 0 0.313rem 0.313rem 0; }

        .nav-link {
            @include fs-14;
            color: $black-2c;
            border: 1px solid $gray-cf;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0;
            padding: 0.25rem 0.875rem;
            
            .c-icon { margin-right: 0.375rem; }

            &.active {
                background-color: #33414A;
                color: $white;

                .c-icon--image {
                    background-image: url(../images/icons/image.svg);
                }
            }
        }
    }
}