.c-footer {
    padding: 0.625rem 0;
    background-color: $black-2c;
    line-height: normal;

    &__logo { opacity: 0.5; }

    &__rpart {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
    }

    .c-copyright {
        @include fs-12;
        color: rgba($white, 0.2);
    }
}

.c-social {
    display: flex;
    padding: 0 0.75rem 0 0;
    margin: 0;
    list-style: none;

    li {
        padding: 0 0.5rem;
    }
}

// Small screen / phone
@media screen and (max-width: 576px) {
    .c-footer {
        &__logo {
            margin: 0 auto 0.75rem;
            display: block;
        }
        &__rpart {
            justify-content: center;
        }
    }    
}

// Small screen / phone
@media screen and (min-width: 576px) {
    
}

// Medium screen / tablet
@media screen and (min-width: 768px) {
    
}


// Large screen / desktop
@media screen and (min-width: 992px) {
    
}

// Extra large screen / wide desktop
@media screen and (min-width: 1200px) {
    
}