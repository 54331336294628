.c-breadcrumb {
    padding-bottom: 1rem;
    @include fs-12;

    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
    }
    &__item {
        color: $black-2c;
        padding-right: 1rem;
        margin-right: 0.625rem;
        background: url(../images/icons/arrow-right.svg) no-repeat right top 0.125rem;

        &:last-child { 
            background: transparent; 
            margin: 0;
            padding: 0;
        }

        .c-icon { margin-right: 0.625rem; }
        
        a { 
            color: rgba($black-2c, 0.5); 

            &:hover {
                color: $black-2c;
                text-decoration: none;
            }
        }
    }
}


// Small screen / phone
@media screen and (min-width: 576px) {
    
}

// Medium screen / tablet
@media screen and (min-width: 768px) { 
    .c-breadcrumb {
        padding-bottom: 2rem;
    }
}

// Large screen / desktop
@media screen and (min-width: 992px) {
    
}

// Extra large screen / wide desktop
@media screen and (min-width: 1200px) {
    
}