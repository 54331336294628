.c-login {
    background: url(../images/gdx-logo-login.svg) no-repeat left -50% center;
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    min-height: calc(100vh - 9rem);
    
    &--footer {
        min-height: calc(100vh - 7.5rem);    
    }


    &__cbox {
        width: calc(100% - 1.875rem);
        margin: 0 auto;
    }
    &__title {
        @include fs-26;
        color: $pink;
        font-weight: 700;        
        text-align: center;
        margin-bottom: 1.875rem;
    }
    &__text {
        @include fs-14;
        text-align: center;
        max-width: 18.5rem;
        margin: 0 auto 1.875rem;
    }
    
    &__button-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1.875rem;
    }

    .c-button {
        min-width: 18.375rem;
        margin-bottom: 3.125rem;
    }

    .c-login-link {
        @include fs-14;
        color: $pink;
        font-weight: 600;
    }
    .c-form-group .form-control { height: 3.125rem; }
}


// Small screen / phone
@media screen and (min-width: 576px) {
    
}

// Medium screen / tablet
@media screen and (min-width: 768px) {
    .c-login {
        padding-top: 5rem;
        //min-height: calc(100vh - 3.75rem);

        &__cbox {
            width: 27.5rem;
            margin: 0 auto;
        }
    }
}

// Large screen / desktop
@media screen and (min-width: 992px) {
    
}

// Extra large screen / wide desktop
@media screen and (min-width: 1200px) {
    
}