div.table-outer {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  overflow: hidden;
}

div.table-outer .pagination-outer {
  background-color: #eeeeee;
}
table {
  width: 100%;
}

tbody th,
tbody td {
  text-align: left;
  padding: 16px;
}

tbody tr:nth-child(odd) {
  background-color: hsla(0, 0%, 93%, 0.5);
}

thead th,
thead td {
  text-align: left;
  padding: 5px 16px;
  font-weight: 400;
  font-size: 12px;
  color: #4E5D67;
}

tbody td:first-child, th:first-child {
  padding-left: 23px;
}

tbody td:last-child, th:last-child {
  padding-right: 23px;
}