.svgLoader {
  animation: spin 0.5s linear infinite;
  margin: auto;
}
.divLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ns-header {
  background-color: #ffe500;
  padding: 10px 15px;
}
.ns-header-title {
  padding: 5px 10px;
}
.inner-ns-header {
  margin: 0px;
  align-items: center;
}
.ns-header-menu {
  flex: 1;
  text-align: end;
  padding: 5px 10px;
}
.ns-header-menu span i {
  padding: 5px;
}
.ns-media-main {
  min-height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #33414a;
}
.ns-media-back {
  position: absolute;
  top: 175px;
  left: 0px;
  z-index: 1;
}
.ns-preview-shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.preview-bio-sub-title {
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.text-center {
  text-align: center;
}
.font-bold {
  font-weight: bold;
}
.content-desc {
  min-height: 190px;
}
.lh-15 {
  line-height: 15px;
}
.fs-12 {
  font-size: 12px;
}
.fs-15 {
  font-size: 15px;
}
.preview-media-box {
  width: 100%;
  height: 100%;
  max-height: 220px;
  background-color: #33414a;
}
.article-content {
  position: absolute;
  left: 0px;
  top: 320px;
  margin-right: 20px;
  padding-right: 40px;
  color: white;
}
.play-button {
  position: absolute;
  z-index: 1;
}
.swiper-pagination-bullet {
  background-color: #fff !important;
}
.swiper-button-prev {
  color: #fff !important;
  border-radius: 0px 50px 50px 0px;
  left: -25px !important;
}
.swiper-button-next {
  color: #fff !important;
  border-radius: 50px 0px 0px 50px;
  right: -25px !important;
}
.swiper-button-lock {
  background-color: rgba(92, 103, 110, 0.7) !important;
  padding: 30px !important;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: medium !important;
  font-weight: bolder !important;
}
.swiper-button-prev:after {
  margin-left: 16px;
}
.swiper-button-next:after {
  margin-right: 16px;
}
.swiper-button-next,
.swiper-button-prev {
  background-color: rgba(92, 103, 110, 0.7) !important;
  padding: 30px !important;
}
.drag-img-close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.img_preview {
  height: 60px;
  width: 60px;
  border-radius: 5px;
  background-color: #33414a;
}
.img_preview_cancel {
  cursor: pointer;
  margin-left: 54px;
  margin-top: -70px;
}
.c-media-library__filters__font {
  font-size: 1.5rem;
  font-weight: bold;
}
.editor_scrolling_height {
  max-height: 250px;
  overflow-y: auto;
}
.preview-scrolling {
  max-height: 512px;
  overflow-y: auto;
}
.invalid-feedback {
  display: block !important;
  color: #dc3545;
}
.invalid-control {
  border-color: #dc3545 !important;
}
.is-invalid {
  border-color: #dc3545 !important;
}
.mw-350 {
  min-width: 350px;
}
.page-item.active .page-link {
  z-index: 0 !important;
}
.cover-image-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.cover-image-container-ml {
  position: relative;
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: center;
}

.cover-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  background-position: center;
  background-color: #33414a;
}
.cover-image-top-layer {
  background-color: rgb(51, 65, 74, 0.3);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.cover-image-top-layer-text {
  text-align: center;
  font-weight: 700;
  color: #ffffff;
}
.cover-image-top-layer-img {
  height: 50%;
  width: 50%;
}
.postion-bottom-right {
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 16px;
  padding-bottom: 10px;
}
.cover-image-top-layer-img-ml {
  height: 30px !important;
}
.dropdown-style {
  z-index: 2;
  position: absolute;
  font-family: Arial, Helvetica, sans-serif;
}
.dropdown-box {
  border: 1px #cf0b48 solid;
  border-top: 0px;
}
.pointer {
  cursor: pointer;
}
.media-item-video-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.full-size {
  width: 100% !important;
}
.sidebar-icon {
  height: 1rem;
  width: 1rem;
}
.sidebar-text {
  font-size: 14px;
}
.country-code-container {
  position: absolute;
  padding: 7px;
  margin-top: 29px;
  margin-left: 1px;
  background-color: #eeeeee;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  border-right: 1;
  border-color: #ccc;
  border-right-style: solid;
}
.flag {
  width: 16px;
  height: 12px;
}
.flag-container {
  max-height: 300px;
  max-width: 25%;
  overflow-y: auto;
  position: absolute;
}
.select-item {
  padding: 2px 15px;
  cursor: pointer;
}
.select-item:hover {
  background-color: #eeeeee;
}
.pl-10 {
  padding-left: 100px !important;
}
.last-news-video-box {
  max-width: 260px !important;
  height: 140px !important;
  background-color: #33414a;
}
.last-news-image-box {
  max-width: 140px !important;
  height: 140px !important;
  background-color: #33414a;
}
.z-10 {
  z-index: 10;
}
.modal-outer {
  max-width: 80%;
  inset: 40px 40px 40px 10%;
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  padding: 0px;
  overflow: scroll
}
.height-fit-content {
  max-height: max-content;
}
.img_preview_commentary {
  height: 24px;
  width: 24px;
}
.border-radius-50-p {
  border-radius: 50%;
}
.club-home-logo{
  height: 110px;
  width: 110px;
  border-radius: 50%;
}