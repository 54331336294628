.c-button {
    display: inline-flex;
    justify-content: center;
    border-radius: 0.313rem;
    padding: 0.625rem 1.25rem;
    cursor: pointer;
    @include fs-14;

    &--fs-16 {
        @include fs-16;
    }

    &--small {
        padding: 0.313rem 0.75rem;
    }

    &--bold { font-weight: 700; }

    &--icons {
        align-items: center;
        .c-icon { margin-right: 0.5rem; }
    }

    &:hover {
        text-decoration: none;
    }

    &--we-160 { width: 10rem; }

    &--bg {
        border: 0;

        &--white { 
            color: $black-2c;
            background-color: $white; 
            border: solid 1px $gray-cf;

            &:hover {
                color: $black-2c;
            }
        }

        &--pink { 
            color: $white;
            background-color: $pink; 

            &:hover {
                color: $white;
            }
        }

        &--black-33 { 
            color: $white;
            background-color: $black-33; 

            &:hover {
                color: $white;
            }
        }

        &--pink-gradiant { 
            color: $white;
            background: transparent linear-gradient(270deg, #CF0B48 0%, #4333D9 100%) 0% 0% no-repeat padding-box;

            &:hover {
                color: $white;
            }
        }

    }
    
    &--br {
        border-width: 1px;
        border-style: solid;

        &--gray-ab {
            color: $black-2c;
            border-color: $gray-ab;

            &:hover {
                color: $black-2c;
            }
        }

        &--gray-70 {
            color: $white;
            border-color: $gray-70;

            &:hover {
                color: $white;
            }
        }

        
        
    }
}

// Pink link with icons
.c-button-links {
    @include fs-14;
    color: $pink;
    font-weight: 700;
    display: inline-flex;
    align-items: center;

    .c-icon {
        margin-right: 0.25rem;    
    }
}

// Pink link with icons
.c-delete {
    display: inline-flex;
    align-items: center;
    @include fs-14;
    color: $black-2c;
    background-color: transparent;
    border: 0;

    .c-icon {
        margin-right: 0.5rem;    
    }
}