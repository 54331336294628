// User dropdown
.c-user-dropdown {
    &__button {
        border: 0;
        background: transparent;
        padding: 0;

        &::after { display: none; }
        &:focus { outline: 0; }
    }
    &__menu {}
}

// More dropdown
.c-more-dropdown {
    &__button {
        cursor: pointer;

        &::after { display: none; }
    }

    &__menu {
        width: 14.625rem;
        padding: 0;
        margin: 0.5rem 0 0 0;
        border: 0;
        box-shadow: 0 0 1.875rem rgba($black-22, 0.34);
    }

    .dropdown-item {
        @include fs-14;
        color: $black-2c;
        font-weight: 500;
        padding: 0.75rem 1.25rem;
        display: flex;
        align-items: center;
        
        .c-icon {
            margin-right: 0.625rem;
        }
        & + .dropdown-item { border-top: solid 1px $gray-cf; }
    }
}