.showDateRow {
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.dateText {
  cursor: pointer;
  color: #CF0B48 !important ;
  text-decoration: none !important;
  margin-top: 2px;
}
.dateClearBtn {
  margin: 0px;
  margin-top: 3px;
  margin-left: 10px;
  margin-right: -5px;
}
.cleanWrappper {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.tagWrappper {
  margin-left: -23px;
}
.searchWrappper {
  margin-right: -10px;
}
.selected {
  border: 3px #0bcf65 solid !important;
}