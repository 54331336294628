.c-header {
    background-color: $black-2c;
    padding: 0.625rem 0.938rem;
    z-index: 9;
    position: relative;

    &__rpart {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;

        > div {
            margin-left: 1.75rem;
            line-height: normal;
        }

        .c-icon--hamburger { 
            margin-left: 1.25rem; 
            cursor: pointer;
        }
    }

    .c-mail,
    .c-notification {
        position: relative;
        
        &__notify {
            position: absolute;
            top: -0.375rem;
            right: -0.625rem;
            background-color: $pink;
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
            color: $white;
            font-weight: 700;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            @include fs-11;
        }
    }

    .c-mail {
        &__notify {}
    }

    .c-notification {
        &__notify {}
    }
   
}

// Small screen / phone
@media screen and (max-width: 576px) {
    .c-logo img {
        max-width: 6.25rem;
    } 
}

// Small screen / phone
@media screen and (min-width: 576px) {
    
}

// Medium screen / tablet
@media screen and (min-width: 768px) {
    .c-header {
        &__rpart > div {
            margin-left: 2.5rem;
        }    
    }    
}

// Large screen / desktop
@media screen and (min-width: 992px) {
    .c-header__rpart .c-icon--hamburger { display: none; }
}

// Extra large screen / wide desktop
@media screen and (min-width: 1200px) {
    
}